import React from 'react'
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import {Grid, Cell} from "react-foundation";
import {Grid as DataGrid, GridColumn as Column, GridNoRecords} from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import { Window } from "@progress/kendo-react-dialogs";
import {orderBy} from "@progress/kendo-data-query";
import axiosApi from "../../api/axiosInstance";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import { filterBy } from "@progress/kendo-data-query";

const ViewCellLink = (props) => {
    return (
        <td>
            <a href={`/landing/${props.dataItem.id}`}>
                {props.dataItem.id}
            </a>
        </td>
    )
}
const loadingPanelMarkup  = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);
function Sites() {
    const [showLoading, setShowLoading] = useState(true);

    const [siteList, setSiteList] = React.useState([]);
    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "name",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setSiteList(getSites(event.sort));
        setSort(event.sort);
    };
    const getSites = (sort) => {
        return orderBy(siteList, sort);
    };

    const [visible, setVisible] = React.useState(true);

    useEffect(() => {
        const loadSites = async () => {
            axiosApi.get(
                '/sites',
                {
                    params: {
                    }
                }).then(function (response) {

               // console.log(response.data);
                if (response['data']['data'].length > 0) {
                    setSiteList(response['data']['data']);
                }
                setShowLoading(false);
            });
        }
        loadSites();
    }, []);

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>All Sites</strong></div>
                <Grid className="display"
                      style={{
                        height: "800px",
                        }}

                >

                    <Cell small={12} medium={12} large={12}>
                        {/*<div>
                            <Link to={'/add-site'}><button
                                title="Add Site"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            >
                                Add Site
                            </button></Link>
                        </div> */}
                        <ExcelExport data={siteList} ref={_export}>
                            { showLoading && loadingPanelMarkup }
                            <DataGrid
                                style={{
                                    height: "800px",
                                }}

                                data={filterBy(siteList, filter)}
                                filterable={true}
                                filter={filter}
                                onFilterChange={(e) => setFilter(e.filter)}
                                sortable={true}
                                sort={sort}
                                onSortChange={sortChange}
                            >
                                <Toolbar>
                                    <button
                                        title="Export Excel"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={excelExport}
                                    >
                                        Export to Excel
                                    </button>
                                </Toolbar>
                                <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                    <Column field="id" title="Id" width="150px" cell={ViewCellLink} />
                                    <Column field="name" title="Name" />
                                    <Column field="site_category" title="Category" />
                                    <Column field="site_type" title="Type" />
                                    <Column field="reference" title="Reference" />
                                    <Column field="floor_area" title="FloorArea" />
                                    <Column field="location" title="Location" />
                                    <Column field="council" title="Council" />
                                    <Column field="full_address" title="Address" />

                            </DataGrid>
                        </ExcelExport>
                    </Cell>
                </Grid>
            </div>
        </div>
    );

}

export default Sites;