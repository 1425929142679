import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React from "react";
import { useState } from "react";
import axiosApi from "../../api/axiosInstance";
// import * as React from "@types/react";

function AddWaterMeter() {
    const { site } = useParams();

    const [meterName, setMeterName] = useState("");
    const [measurementUnit, setMeasurementUnit] = useState("");
    const [initialReading, setInitialReading] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSave = (event) => {
        event.preventDefault(); 
        axiosApi.post('/pillar/2/site-water-meters?site_id=' + site, {
            site_id: site,
            meter_name: meterName,
            metric_unit: measurementUnit,
            initial_reading: initialReading
        })
        .then((response) => {
            console.log(response.data);
            // Clear form inputs
            setMeterName("");
            setMeasurementUnit("");
            setInitialReading("");
            // Set success message
            setSuccessMessage("Water meter added successfully!");
        })
        .catch((error) => {
            console.error(error);
            // Handle error if needed
        });
    };

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="waterNavigation">
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/water-meters/'+site} className="nav-link">Water Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/water-meter-entries/'+site} className="nav-link">Water Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/water/'+site} className="nav-link">Water Meter Entry Upload</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/water-meters/' + site}>&#11164; Back to Water Meters</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Add Water Meter</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={3} large={3}>
                                <div className="quickSelectTab">
                                    <h4>Water Meter</h4>
                                    <form onSubmit={handleSave}>
                                        <hr/>
                                            <input type="text" id="meter_name" name="meter_name" value={meterName} placeholder="Meter Name" maxlength="50" size="30" onChange={(e) => setMeterName(e.target.value)}/>
                                        <hr/>
                                        <select name="measurment_unit" id="measurment_unit" value={measurementUnit} onChange={(e) => setMeasurementUnit(e.target.value)}>
                                            <option value=''>Please Select a Measurement Unit</option>
                                            <option value='AcreFt'>AcreFt</option>
                                            <option value='CuFt'>CuFt</option>
                                            <option value='Gallons'>Gallons</option>
                                            <option value='Litres'>Litres</option>
                                            <option value='M3'>M3</option>
                                        </select>
                                        <hr/>
                                            <input type="number" id="initial_reading" name="initial_reading" value={initialReading} placeholder="Initial Reading" onChange={(e) => setInitialReading(e.target.value)}/>
                                        <hr/>
                                        <button type="submit" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Save</button>
                                        {successMessage && <p>{successMessage}</p>}
                                    </form>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default AddWaterMeter;