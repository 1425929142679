import * as React from "react";
import {Grid, Cell} from "react-foundation";
import {
    Grid as DataGrid,
    GridColumn as Column,
    GridNoRecords,
    GridToolbar as Toolbar
} from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {FormatEntries} from "./TransportHelper";

const initialDataState = {
    skip: 0,
    take: 25,
};
function TransportList() {

    const [TransportDataTable, setTransportDataTable] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [showLoading, setShowLoading] = useState(true);

    const params = useParams();
    const site = params['site'];

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setTransportDataTable(getSortEntries(event.sort));
        setSort(event.sort);
    };
    const getSortEntries = (sort) => {
        return orderBy(TransportDataTable, sort);
    };


    const [page, setPage] = React.useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        const take =
            targetEvent.value === "All" ? TransportDataTable.length : event.page.take;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadTransportList = async () => {

            let site_id = site;
            let url = '/pillar/2/site-transports';
            let params = {
                type: 'site',
                site_id: site_id,
                start_date: startDate,
                end_date: endDate
            };

            //get energy data
            axiosApi.get(
                url,
                {
                    params: params
                }
            ).then(function (response) {
                let data = response['data'];
                let entries = [];

                entries = data['data']['list'] ?? [];

                setTransportDataTable(FormatEntries(entries,false));
                setShowLoading(false);
            });
            //get data for the table

        }
        loadTransportList();

    },[site,startDate,endDate]);


    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Transport Reporting</h5>
                    <hr/>
                    <ul>
                        <li>
                            <Link to={'/dashboard/transportLanding/' + site} className="nav-link">Transport Report</Link>
                        </li>
                        <li>
                            <Link to={'/transport/list/' + site} className="nav-link">Transport List</Link>
                        </li>
                    </ul>
                    <hr/>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/transportLanding/' + site} className="linkBack">&#11164; Back to Transport Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Transport List -{ siteName }</strong></div>
                        <Grid className="display">

                            <Cell small={12} medium={12} large={12}>
                                <ExcelExport data={TransportDataTable} ref={_export}>
                                    <DataGrid
                                        style={{
                                            height: "1200px",
                                        }}

                                        data={filterBy(TransportDataTable, filter).slice(page.skip, page.take + page.skip)}
                                        skip={page.skip}
                                        take={page.take}
                                        total={filterBy(TransportDataTable, filter).length}
                                        pageable={{
                                            buttonCount: 4,
                                            pageSizes: [5, 10, 15, "All"],
                                            pageSizeValue: pageSizeValue,
                                        }}
                                        onPageChange={pageChange}
                                        filterable={true}
                                        filter={filter}
                                        onFilterChange={(e) => setFilter(e.filter)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={sortChange}

                                    >
                                        <Toolbar>
                                            <button
                                                title="Export Excel"
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                onClick={excelExport}
                                            >
                                                Export to Excel
                                            </button>
                                        </Toolbar>
                                        <GridNoRecords> {showLoading ? 'Please Wait the Records are Loading' : 'No Records are available'}</GridNoRecords>
                                        <Column field="Registration" title="Registration" />
                                        <Column field="Make" title="Make" />
                                        <Column field="Model" title="Model" />
                                        <Column field="Type" title="Type" />
                                        <Column field="Miles" title="Miles" />
                                        <Column field="CO2eTotal" title="CO2eTotal" format="{0:n2}" />
                                        <Column field="tCO2eTotal" title="tCO2eTotal" format="{0:n4}" />
                                        <Column field="From" title="From" />
                                        <Column field="To" title="to" />
                                        <Column field="Date" title="Date" />
                                        <Column field="FromTime" title="FromTime" />
                                        <Column field="ToTime" title="ToTime" />
                                        <Column field="Source" title="Source" />
                                    </DataGrid>
                                </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default TransportList;