import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {FormatEnergyEntries} from "../Energy/EnergyHelper";
import EnergyNavbar from "../EnergyNavbar";

// import * as React from "@types/react";

function EnergyMeters() {
    const [siteName, setSiteName] = useState('');
    const [energyName, setEnergyName] = useState('');
    const [meterEntryList, setMeterEntryList] = React.useState([]);

    const params = useParams();
    const site = params['site'];
    const id = params['id'];

    const value = new Date();

    const [filter, setFilter] = React.useState();
    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];
    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setMeterEntryList(getMeterEntries(event.sort));
        setSort(event.sort);
    };
    const getMeterEntries = (sort) => {
        return orderBy(meterEntryList, sort);
    };

    const initialDataState = {
        skip: 0,
        take: 25,
    };

    const [page, setPage] = React.useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        const take =
            targetEvent.value === "All" ? meterEntryList.length : event.page.take;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadEnergyType = async () => {
            axiosApi.get(
                '/pillar/2/energy-types/' + id,
            ).then(function (response) {
                if (response['data']['data']) {
                    setEnergyName(response['data']['data']['name']);
                }
            });
        }
        loadEnergyType();


        const loadMeterEntries = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries',
                {
                    params: {
                        site_id : site,
                        energy_type_id : id,
                        start_date: startDate,
                        end_date: endDate
                    }
                }).then(function (response) {
                let data = response['data']['data'];

                let result = FormatEnergyEntries(data, false);
                setMeterEntryList(result['energy_table_data']);

            });
        }
        loadMeterEntries();
    },[site,id,startDate,endDate]);
    return (
        <Grid>
            <EnergyNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/' + site}>&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Energy Meters Entries-{ siteName }</strong></div>
                        <Grid className="display"
                              style={{
                                  height: "800px",
                              }}

                        >
                            <Cell small={12} medium={12} large={12}>
                            <ExcelExport data={meterEntryList} ref={_export} fileName={siteName + '_' + energyName +'.xlsx'}>

                                <DataGrid
                                    style={{
                                        height: "800px",
                                    }}

                                    data={filterBy(meterEntryList, filter).slice(page.skip, page.take + page.skip)}
                                    skip={page.skip}
                                    take={page.take}
                                    total={filterBy(meterEntryList, filter).length}
                                    pageable={{
                                        buttonCount: 4,
                                        pageSizes: [5, 10, 15, "All"],
                                        pageSizeValue: pageSizeValue,
                                    }}
                                    onPageChange={pageChange}
                                    filterable={true}
                                    filter={filter}
                                    onFilterChange={(e) => setFilter(e.filter)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={sortChange}
                                >
                                    <Toolbar>
                                        <button
                                            title="Export Excel"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            onClick={excelExport}
                                        >
                                            Export to Excel
                                        </button>
                                    </Toolbar>

                                    <Column field="Meter" title="Meter" width="150" />
                                    <Column field="EnergyType" title="Energy Type" />
                                    <Column field="Date" title="Date" />
                                    <Column field="Amount" title="Amount" format="{0:n2}"/>
                                    <Column field="CO2eTotal" title="CO2e Total" format="{0:n2}"/>
                                    <Column field="CO2eTotalPerMsq" title="CO2e Total Per M2" format="{0:n2}"/>
                                    <Column field="CO2eTotalPerHund" title="CO2e Total Per100k" format="{0:n2}"/>
                                    <Column field="Kwh" title="kWh" format="{0:n2}"/>
                                    <Column field="Kitchen" title="Kitchen" format="{0:n2}"/>
                                    <Column field="School" title="School" format="{0:n2}"/>
                                    <Column field="General" title="General" format="{0:n2}"/>
                                    <Column field="InputType" title="InputType"/>
                                    <Column field="BillType" title="BillType"/>

                                </DataGrid>
                            </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default EnergyMeters;