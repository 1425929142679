import {Cell, Grid} from "react-foundation";
import {useNavigate, Link, NavLink} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import * as ReactDOM from "react-dom";
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle, ChartSeries, ChartSeriesItem,
    ChartTitle, Sparkline,
    ChartSeriesLabels, ChartLegend,
    ChartTooltip,
    ChartSeriesItemTooltip, ChartXAxisItem, ChartXAxis
} from "@progress/kendo-react-charts";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid as DataGrid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import axios from "axios";
import axiosApi from "../api/axiosInstance";
import $ from "jquery";
import "hammerjs";
import {useEffect, useState, useRef} from "react";
import * as React from "react";
import fleetData from "../data/fleet.json";
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {FormatWasteEntries} from "./Waste/WasteHelper";
import {parseNumber} from "./form/format-helper";
import {formatNumber} from "@progress/kendo-intl";

const initialDataState = {
    skip: 0,
    take: 10,
};

const Content = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [conversionLoading, setConversionLoading] = useState(true);

    const[floorArea, setFloorArea] = useState(0);
    const[students, setStudents] = useState(0);

    // set data set for shared regions
    const [scopeData, setScopeData] = useState([]);
    const [scopeChart, setScopeChart] = useState([]);
    const [subScopeData, setSubScopeData] = useState([]);
    const [subScopeChart, setSubScopeChart] = useState([]);
    const [subScopeLabels, setSubScopeLabels] = useState([]);


    const [energyBreakdownData, setEnergyBreakdownData] = useState([]);
    const [baselineEnergyBreakdownData, setBaselineEnergyBreakdownData] = useState([]);

    const [conversionsData, setConversionsData] = useState([]);
    const [transportData, setTransportData] = useState([]);
    const [transportTableData, setTransportTableData] = useState([]);
    const [typesBreakdownData, setTypesBreakdownData] = useState([]);
    const [typesBreakdownLabels, setTypesBreakdownLabels] = useState([]);
    const [locationsBreakdownData, setLocationsBreakdownData] = useState([]);
    const [locationsBreakdownLabels, setLocationsBreakdownLabels] = useState([]);
    const [schoolsBreakdownData, setSchoolsBreakdownData] = useState([]);
    const [schoolsBreakdownLabels, setSchoolsBreakdownLabels] = useState([]);
    const [schoolsSubbedTopTwentyTable, setSchoolsSubbedTopTwentyTable] = useState([]);
    const [schoolsTotalTopTwentyTable, setSchoolsTotalTopTwentyTable] = useState([]);
    const [schoolsTotalTopTwentyBaseTable, setSchoolsTotalTopTwentyBaseTable] = useState([]);

    const [transportTotalTopTwentyTable, setTransportTotalTopTwentyTable] = useState([]);
    const [transportTotalTopTwentyBaseTable, setTransportTotalTopTwentyBaseTable] = useState([]);

    const [transportMilesTile, setTransportMilesTile] = useState([]);
    const [transportEmissionsTile, setTransportEmissionsTile] = useState([]);
    const [energyEmissionsTile, setEnergyEmissionsTile] = useState([]);

    const [totalWasteSummary, setTotalWasteSummary] = useState([]);
    const [totalSummaryTonnage, setTotalSummaryTonnage] = useState([]);
    const [totalSummaryProcessEmissions, setTotalSummaryProcessEmissions] = useState([]);

    const[filterValue, setFilterValue] = useState(1);
    const[filterType, setFilterType] = useState('');
    const[filterMsg, setFilterMsg] = useState('');
    const[topFilterValue, setTopFilterValue] = useState('Select');

    // const id = params['id'];
    const site = '1275,1265,721,667,810,165';
    // const site = 1275;

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "Source",
            dir: "desc",
        },
    ];

    const [page, setPage] = React.useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = React.useState();
    const pageChange = (event) => {
        const targetEvent = event.targetEvent;
        const take =
            targetEvent.value === "All" ? conversionsData.length : event.page.take;
        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }
        setPage({
            ...event.page,
            take,
        });
    };
    const [filter, setFilter] = React.useState();

    const [sort, setSort] = React.useState(initialSort);
    const sortChange = (event) => {
        setConversionsData(getEntries(event.sort));
        setSort(event.sort);
    };
    const getEntries = (sort) => {
        return orderBy(conversionsData, sort);
    };

    const handleFilterChange = (e) => {
        setShowLoading(true);
        let filterVal = 1;
        let filterType = '';
        let filterMsg = '';
        if(e.target.value == 'per 100M2') {
            filterVal = floorArea ? floorArea/100 : 1;
            filterType = 'floor';
            filterMsg = 'The KgCo2e Value has been updated to per 100M2';
        } else if(e.target.value == 'per Student') {
            filterVal = students ? students : 1;
            filterType = 'student';
            filterMsg = 'The KgCo2e Value has been updated to per Student';
        }

        setFilterValue(filterVal);
        setFilterType(filterType);
        setFilterMsg(filterMsg);
        setTopFilterValue('Select');
    }

    const handleTopFilterChange = (e) => {

        let filterVal =  (e.target.value == 'Select') ? 0 : e.target.value;
        let school_data  = schoolsTotalTopTwentyBaseTable;

        let bySchoolTotal = school_data.slice(0);
        bySchoolTotal.sort(function(a,b) {
            return b.KGCO2E - a.KGCO2E;
        });

        let transport_data  = transportTotalTopTwentyBaseTable;

        let byTransportTotal = transport_data.slice(0);
        byTransportTotal.sort(function(a,b) {
            return b.KGCO2E - a.KGCO2E;
        });



        if(filterVal > 0 )  {
            let totalLength = bySchoolTotal.length;
            let percLength =  (totalLength * filterVal /100);
            bySchoolTotal.length = (percLength > 1) ? parseInt(percLength) : 1;

            let totalTransLength = byTransportTotal.length;
            let percTransLength =  (totalTransLength * filterVal /100);
            byTransportTotal.length = (percTransLength > 1) ? parseInt(percTransLength) : 1;
        }

        setTopFilterValue(e.target.value);
        setSchoolsTotalTopTwentyTable(bySchoolTotal);
        setTransportTotalTopTwentyTable(byTransportTotal);
    }



    const locationBreakdown = ["Antrim", "Armagh", "Belfast", "Dundonald", "Omagh"];
    const categories = ["Scope 1\r\nMobile", "Scope 1\r\nStationary", "Scope 2", "Scope 3"];
    let sourceTypes = ['Electricity', 'Natural Gas', 'LPG', 'Burning Oil (Kerosene)'];

    let scopeLabels = ['Scope 1','Scope 2','Scope 3'];
    let transportLabels = ['Transport'];
    // setSourceCats(sourceTypes);

    const value = new Date();
    const startDate = new Date("2022-09-01");
    const endDate = new Date("2023-08-31");
    const baselineStartDate = new Date("2019-09-01");
    const baselineEndDate = new Date("2020-08-31");

    const baseLineYear = '2019/20';
    const currentYear = '2022/23';


    const [latestBuiltEnvironmentPercentageTarget, setLatestBuiltEnvironmentPercentageTarget] = useState(null);
    const [latestBuiltEnvironmentTargetGoal, setLatestBuiltEnvironmentTargetGoal] = useState(null);
    const [latestWastePercentageTarget, setLatestWastePercentageTarget] = useState(null);
    const [latestWasteTargetGoal, setLatestWasteTargetGoal] = useState(null);
    const [latestTransportPercentageTarget, setLatestTransportPercentageTarget] = useState(null);
    const [latestTransportTargetGoal, setLatestTransportTargetGoal] = useState(null);
    const [latestLandUsePercentageTarget, setLatestLandUsePercentageTarget] = useState(null);
    const [latestLandUseTargetGoal, setLatestLandUseTargetGoal] = useState(null);
    const [latestTotalEnergyEmissionsPercentageTarget, setLatestTotalEnergyEmissionsPercentageTarget] = useState(null);
    const [latestTotalEnergyEmissionsTargetGoal, setLatestTotalEnergyEmissionsTargetGoal] = useState(null);
    const [latestTotalWaterSupplyPercentageTarget, setLatestTotalWaterSupplyPercentageTarget] = useState(null);
    const [latestTotalWaterSupplyTargetGoal, setLatestTotalWaterSupplyTargetGoal] = useState(null);
    const [latestTotalWaterTreatmentPercentageTarget, setLatestTotalWaterTreatmentPercentageTarget] = useState(null);
    const [latestTotalWaterTreatmentTargetGoal, setLatestTotalWaterTreatmentTargetGoal] = useState(null);
    const [latestTotalTonnesPercentageTarget, setLatestTotalTonnesPercentageTarget] = useState(null);
    const [latestTotalTonnesTargetGoal, setLatestTotalTonnesTargetGoal] = useState(null);
    const [latestTotalRecyclePercentageTarget, setLatestTotalRecyclePercentageTarget] = useState(null);
    const [latestTotalRecycleTargetGoal, setLatestTotalRecycleTargetGoal] = useState(null);
    const [latestTotalIncineratePercentageTarget, setLatestTotalIncineratePercentageTarget] = useState(null);
    const [latestTotalIncinerateTargetGoal, setLatestTotalIncinerateTargetGoal] = useState(null);
    const [latestTotalMilesPercentageTarget, setLatestTotalMilesPercentageTarget] = useState(null);
    const [latestTotalMilesTargetGoal, setLatestTotalMilesTargetGoal] = useState(null);
    const [latestTotalTransportEmissionsPercentageTarget, setLatestTotalTransportEmissionsPercentageTarget] = useState(null);
    const [latestTotalTransportEmissionsTargetGoal, setLatestTotalTransportEmissionsTargetGoal] = useState(null);



    useEffect(() => {

        const loadConversionsGraph = async () => {

            let conversions = [];

            let url = '/pillar/2/ghg-emission-conversions';
            let params = {
                country_id : 238
            };

            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let dataset = response['data']['data'];

                for(let i = 0; i < dataset.length; i++) {
                    if(dataset[i]['country']['code'] === 'GB') {

                        //console.log(dataset[i]['pillar_two_energy_type']);
                        if(dataset[i]['pillar_two_energy_type']) {
                            if(dataset[i]['pillar_two_energy_type']['name'] === 'Biofuel') {
                                conversions.push({
                                    Source: dataset[i]['pillar_two_energy_type']['name'],
                                    Subsource: dataset[i]['pillar_two_energy_sub_type']['name'],
                                    Scope: dataset[i]['pillar_two_energy_type']['scope'],
                                    Region: dataset[i]['country']['name'],
                                    Year: dataset[i]['year'],
                                    ConversionFrom: dataset[i]['conversion_from_measurement']['name'],
                                    ConversionTo: dataset[i]['conversion_to_measurement']['name'],
                                    Factor: dataset[i]['factor']
                                });
                            } else {
                                conversions.push({
                                    Source: dataset[i]['pillar_two_energy_type']['name'],
                                    Subsource: 'n/a',
                                    Scope: dataset[i]['pillar_two_energy_type']['scope'],
                                    Region: dataset[i]['country']['name'],
                                    Year: dataset[i]['year'],
                                    ConversionFrom: dataset[i]['conversion_from_measurement']['name'],
                                    ConversionTo: dataset[i]['conversion_to_measurement']['name'],
                                    Factor: dataset[i]['factor']
                                });
                            }
                        }

                    }
                }
                setConversionLoading(false);

            });
            setConversionsData(conversions);
        }
        loadConversionsGraph();
    },[]);

    useEffect(() => {

        let schoolsbreakdown = [];
        let schoolsdata = [];
        let schoolsbreakdownlabels = [];
        let schoolsdatatable = [];
        let schoolsSubbeddatatable = [];

        let transportvehicledata = [];

        let scopechartbreakdown = [];
        let scopetablebreakdown = [];


        let subscopechartbreakdown = [];
        let subscopetablebreakdown = [];
        let subscopelabelsset = [];

        let energybreakdown = [];
        let comparebreakdown = [];

        let typesdata = [];
        let typesbreakdownlabels = [];

        let locationsdata = [];
        let locationsbreakdownlabels = [];

        const loadBaseYearEnergyData = async () => {

            let url = '/pillar/2/site-energy-meter-entries/company-report-summary';
            let params = {
                site_id: site,
                start_date: baselineStartDate,
                end_date: baselineEndDate,
                by_scope: 1
            };


            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {

                // Get Charts And Data By Scope for BaseLine Year

                let data_by_scope = response['data']['data']['summary']['by_scope'];

                let scopechart = [];
                let subscopechart = [];

                for(let i = 0; i < data_by_scope.length; i++) {
                    let kgco2e_amount = parseNumber(data_by_scope[i]['ghg_emissions'][1]['total_amount']/filterValue);

                    scopechart.push(kgco2e_amount);

                    scopetablebreakdown.push({
                        Source: data_by_scope[i]['scope'],
                        Year: baseLineYear,
                        KGCO2E: kgco2e_amount,
                        Unit: 'kgco2e'
                    });

                    for(let k = 0; k < data_by_scope[i]['by_sub_scope'].length; k++) {
                        let amount= parseNumber(data_by_scope[i]['by_sub_scope'][k]['ghg_emissions'][1]['total_amount'] / filterValue)
                        subscopechart.push(amount);

                        subscopetablebreakdown.push({
                            Source: data_by_scope[i]['by_sub_scope'][k]['sub_scope'],
                            Year: baseLineYear,
                            KGCO2E: amount,
                            Unit: 'kgco2e'
                        });

                        subscopelabelsset.push(data_by_scope[i]['by_sub_scope'][k]['sub_scope']);
                    }
                }
                scopechart.push(parseNumber('0.000'));

                scopechartbreakdown.push({
                    name: baseLineYear,
                    data: scopechart
                });

                subscopechartbreakdown.push({
                    name: baseLineYear,
                    data: subscopechart
                });
            });
        }
        loadBaseYearEnergyData();

        const loadCurYearEnergyData = async () => {

            let url = '/pillar/2/site-energy-meter-entries/company-report-summary';
            let params = {
                site_id: site,
                start_date: startDate,
                end_date: endDate,
                by_site: 1,
                by_scope: 1,
                by_site_category: 1,
                by_site_location: 1,
                by_energy_type: 1
            };


            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {

                // Get Charts And Data By Scope.
                let data_by_scope = response['data']['data']['summary']['by_scope'];
                let scopechart = [];
                let subscopechart = [];

                for(let i = 0; i < data_by_scope.length; i++) {
                    let kgco2e_amount = parseNumber(data_by_scope[i]['ghg_emissions'][1]['total_amount']/filterValue);

                    scopechart.push(kgco2e_amount);

                    scopetablebreakdown.push({
                        Source: data_by_scope[i]['scope'],
                        Year: currentYear,
                        KGCO2E: kgco2e_amount,
                        Unit: 'kgco2e'
                    });

                    for(let k = 0; k < data_by_scope[i]['by_sub_scope'].length; k++) {
                        let amount= parseNumber(data_by_scope[i]['by_sub_scope'][k]['ghg_emissions'][1]['total_amount'] / filterValue)
                        subscopechart.push(amount);

                        subscopetablebreakdown.push({
                            Source: data_by_scope[i]['by_sub_scope'][k]['sub_scope'],
                            Year: currentYear,
                            KGCO2E: amount,
                            Unit: 'kgco2e'
                        });

                    }

                }
                scopechart.push(parseNumber('0.000'));

                scopechartbreakdown.push({
                    name: currentYear,
                    data: scopechart
                });

                subscopechartbreakdown.push({
                    name: currentYear,
                    data: subscopechart
                });


                // Get Energy Data by School.

                let data_by_site = response['data']['data']['summary']['by_site'];

                let total_students = 0;
                let total_floorarea = 0;

                for (let i = 0; i < data_by_site.length; i++) {
                    let schoolFilterVal = 1;
                    if(filterType == 'floor') {
                        schoolFilterVal = data_by_site[i]['floor_area'] / 100;
                    } else if(filterType == 'student') {
                        schoolFilterVal = data_by_site[i]['total_students'];
                    }

                    total_floorarea += parseFloat(data_by_site[i]['floor_area']);
                    total_students += parseInt(data_by_site[i]['total_students']);

                    let school_name =  data_by_site[i]['site_name'];
                    let kgco2e_amount = parseNumber(data_by_site[i]['ghg_emissions'][1]['total_amount'] / schoolFilterVal);

                    schoolsbreakdownlabels.push(school_name);
                    schoolsbreakdown.push(kgco2e_amount);

                    schoolsdatatable.push({
                        Source: kgco2e_amount,
                        School: school_name,
                        Year: currentYear,
                        KGCO2E: kgco2e_amount,
                        Unit: 'kgco2e',
                        FloorArea:  data_by_site[i]['floor_area'],
                        Students : data_by_site[i]['total_students']
                    });

                    let elec = 0;
                    let ng = 0;
                    let lpg = 0;
                    let bo = 0;

                    for(let k = 0; k < data_by_site[i]['by_energy_type'].length; k++) {
                        let amount = parseNumber(data_by_site[i]['by_energy_type'][k]['ghg_emissions'][1]['total_amount'] / schoolFilterVal)
                        if(data_by_site[i]['by_energy_type'][k]['energy_type'] === 'Electricity') {
                            elec = amount;
                        } else if(data_by_site[i]['by_energy_type'][k]['energy_type'] === 'Natural Gas') {
                            ng = amount;
                        } else if(data_by_site[i]['by_energy_type'][k]['energy_type'] === 'LPG') {
                            lpg = amount;
                        } else if(data_by_site[i]['by_energy_type'][k]['energy_type'] === 'Burning Oil') {
                            bo = amount;
                        }
                    }

                    schoolsSubbeddatatable.push({
                        Source: data_by_site[i]['site_name'],
                        Year: currentYear,
                        Electric: elec,
                        Gas: ng,
                        Lpg: lpg,
                        Oil: bo,
                        Unit: 'kgco2e'
                    });
                }
                schoolsdata.push({
                    name: currentYear,
                    data: schoolsbreakdown
                })

                setFloorArea(total_floorarea);
                setStudents(total_students);

                // Energy Type BreakDown
                let data_by_energy_type =  response['data']['data']['summary']['by_energy_type'];
                let elec = 0;
                let ng = 0;
                let lpg = 0;
                let bo = 0;
                let currentenergybreakdown = [];

                if(data_by_energy_type.length > 0) {
                    for(let i = 0; i < data_by_energy_type.length; i++) {
                        let amount  = parseNumber(data_by_energy_type[i]['ghg_emissions'][1]['total_amount']);

                        energybreakdown.push({
                            Source: data_by_energy_type[i]['energy_type'],
                            Period: currentYear,
                            Amount: amount / filterValue,
                            Unit: 'kgco2e'
                        });

                        if(data_by_energy_type[i]['energy_type'] === 'Electricity') {
                            elec = amount;
                        } else if(data_by_energy_type[i]['energy_type'] === 'Natural Gas') {
                            ng = amount;
                        } else if(data_by_energy_type[i]['energy_type'] === 'LPG') {
                            lpg = amount;
                        } else if(data_by_energy_type[i]['energy_type'] === 'Burning Oil') {
                            bo = amount;
                        }

                        currentenergybreakdown = [elec,ng,lpg,bo];

                        comparebreakdown.push({
                            name: currentYear,
                            data: currentenergybreakdown
                        });

                        setEnergyEmissionsTile((elec + ng + lpg + bo))

                    }
                }

                // Get Energy Data by Site Category

                let data_by_category = response['data']['data']['summary']['by_site_category'];
                let typesbreakdown = [];


                for (let i = 0; i < data_by_category.length; i++) {
                    typesbreakdown.push(parseNumber(data_by_category[i]['ghg_emissions'][1]['total_amount'] / filterValue));
                    typesbreakdownlabels.push(data_by_category[i]['category_name']);
                }
                typesdata.push({
                    name: currentYear,
                    data: typesbreakdown
                })

                // Get Energy Data by Site Location

                let data_by_location = response['data']['data']['summary']['by_site_location'];
                let locationsbreakdown = [];

                for (let i = 0; i < data_by_location.length; i++) {
                    locationsbreakdown.push(parseNumber(data_by_location[i]['ghg_emissions'][1]['total_amount'] /  filterValue));

                    locationsbreakdownlabels.push(data_by_location[i]['location_name']);
                }

                locationsdata.push({
                    name: currentYear,
                    data: locationsbreakdown
                })


            });

        }
        loadCurYearEnergyData();

        let transportdataset = [];
        let transportdatatable = [];

        const loadBaseLineTransportGraph = async () => {

            let url = '/pillar/2/site-transports/company-summary/workshop';
            let params = {
                start_date: baselineStartDate,
                end_date: baselineEndDate
            };

            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let entrycheck = response['data']['data']['summary'];

                let kgco2e = 0;
                if(entrycheck['overall'].length > 0) {
                    kgco2e = parseNumber(entrycheck['overall'][0]['total_amount']);
                }

                transportdataset.push({
                    name: baseLineYear + ' Transport',
                    data: [kgco2e]
                });

                transportdatatable.push({
                    Source: 'Transport ' + baseLineYear,
                    Year: baseLineYear,
                    Miles: entrycheck['total_miles'] ? parseNumber(entrycheck['total_miles']) : 0,
                    Km: entrycheck['total_kilometers'] ? parseNumber(entrycheck['total_kilometers']) : 0,
                    KGCO2E: kgco2e,
                    Unit: 'kgco2e'
                });
            });

        }
        loadBaseLineTransportGraph();

        const loadCurYearTransportGraph = async () => {

            let url = '/pillar/2/site-transports/company-summary/workshop';
            let params = {
                start_date: startDate,
                end_date: endDate,
                by_vehicle_type: 1
            };

            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let entrycheck = response['data']['data']['summary'];

                let entry = 0;
                if(entrycheck['overall'].length > 0) {
                    entry = parseNumber(entrycheck['overall'][0]['total_amount']);
                }
                let totalmiles = entrycheck['total_miles'] ? parseNumber(entrycheck['total_miles']) : 0;

                transportdataset.push({
                    name: currentYear +' Transport',
                    data: [entry]
                });

                transportdatatable.push({
                    Source: 'Transport ' + currentYear,
                    Year: currentYear,
                    Miles: totalmiles,
                    Km: entrycheck['total_kilometers'] ? parseNumber(entrycheck['total_kilometers']) : 0,
                    KGCO2E: entry,
                    Unit: 'kgco2e'
                });

                if(filterType == '') {
                    energybreakdown.push({
                        Source: 'Transport',
                        Period: currentYear,
                        Amount: entry,
                        Unit: 'kgco2e'
                    });
                }

                setTransportMilesTile(totalmiles);
                setTransportEmissionsTile(entry);

                // Get Transport by Vehicle Type

                let transport_by_vehicle = response['data']['data']['summary']['by_vehicle_type'];

                for (let i = 0; i < transport_by_vehicle.length; i++) {

                    let vehicle_name =  transport_by_vehicle[i]['vehicle_type_name'];
                    let kgco2e_amount = parseNumber(transport_by_vehicle[i]['ghg_emissions'][0]['total_amount']);

                    transportvehicledata.push({
                        Source: vehicle_name,
                        Year: currentYear,
                        KGCO2E: kgco2e_amount,
                        Unit: 'kgco2e',
                    });
                }

            });
        }
        loadCurYearTransportGraph();


        setTransportData(transportdataset);
        setTransportTableData(transportdatatable);

        setSchoolsBreakdownData(schoolsdata);
        setSchoolsBreakdownLabels(schoolsbreakdownlabels);
        setSchoolsTotalTopTwentyTable(schoolsdatatable);
        setSchoolsTotalTopTwentyBaseTable(schoolsdatatable);

        setSchoolsSubbedTopTwentyTable(schoolsSubbeddatatable);

        setTransportTotalTopTwentyTable(transportvehicledata);
        setTransportTotalTopTwentyBaseTable(transportvehicledata);

        setTypesBreakdownData(typesdata);
        setTypesBreakdownLabels(typesbreakdownlabels);

        setLocationsBreakdownData(locationsdata);
        setLocationsBreakdownLabels(locationsbreakdownlabels);

        setScopeData(scopetablebreakdown);
        setScopeChart(scopechartbreakdown);
        setSubScopeData(subscopetablebreakdown);
        setSubScopeChart(subscopechartbreakdown);
        setSubScopeLabels(subscopelabelsset);

        setEnergyBreakdownData(energybreakdown);

        setShowLoading(false);

    },[filterValue, filterType]);



    useEffect(() => {

        const loadWasteUsageGraph = async () => {

            let url = '/pillar/2/site-waste-entries-summary';
            let params = {
                company_id: 1,
            };

            axiosApi.get(
                url,
                {
                    params: params
                }
            ).then(function (response) {
                let data = response['data']['data'];

                let result = FormatWasteEntries(data);

                setTotalWasteSummary(result['total_summary']);
                setTotalSummaryTonnage(result['total_summary']['amount']);
            });
        }
        loadWasteUsageGraph();

    },[]);



     useEffect(() => {
        const getCompanyTargets = async () => {

            axiosApi.get(
                '/company-targets',
            ).then(function (response) {
                if (response.data.data.list.length > 0) {
                    // Filter entries with target_measurement_type of 'Built Environment'
                    const builtEnvironmentTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Built Environment');
                    const wasteTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Waste');
                    const transportTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Transport');
                    const landUseTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Land Use');
                    const totalEnergyEmissionsTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Total Energy Emissions');
                    const totalWaterSupplyTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Total Water Supply');
                    const totalWaterTreatmentTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Total Water Treatment');
                    const totalTonnesTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Waste Tonnage');
                    const totalRecycleTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Process Emissions(Recycle)');
                    const totalIncinerateTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Process Emissions(Incinerate)');
                    const totalMilesTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Total Miles');
                    const totalTransportEmissionsTargets = response.data.data.list.filter(entry => entry.target_measurement_type === 'Transport Emissions');

                    // Find the latest entry by date for each target_measurement_type
                    const currentDate = new Date();
                    const latestBuiltEnvironmentEntry = getLatestEntry(builtEnvironmentTargets, currentDate);
                    const latestWasteTargetsEntry = getLatestEntry(wasteTargets, currentDate);
                    const latestTransportTargetsEntry = getLatestEntry(transportTargets, currentDate);
                    const latestLandUseTargetsEntry = getLatestEntry(landUseTargets, currentDate);
                    const latestTotalEnergyEmissionsTargetsEntry = getLatestEntry(totalEnergyEmissionsTargets, currentDate);
                    const latestTotalWaterSupplyTargetsEntry = getLatestEntry(totalWaterSupplyTargets, currentDate);
                    const latestTotalWaterTreatmentTargetsEntry = getLatestEntry(totalWaterTreatmentTargets, currentDate);
                    const latestTotalTonnesTargetsEntry = getLatestEntry(totalTonnesTargets, currentDate);
                    const latestTotalRecycleTargetsEntry = getLatestEntry(totalRecycleTargets, currentDate);
                    const latestTotalIncinerateTargetsEntry = getLatestEntry(totalIncinerateTargets, currentDate);
                    const latestTotalMilesTargetsEntry = getLatestEntry(totalMilesTargets, currentDate);
                    const latestTotalTransportEmissionsTargetsEntry = getLatestEntry(totalTransportEmissionsTargets, currentDate);

                    // Set the latest percentage target to state
                    if (latestBuiltEnvironmentEntry) {
                        setLatestBuiltEnvironmentPercentageTarget(latestBuiltEnvironmentEntry.percentage_target);
                        setLatestBuiltEnvironmentTargetGoal(latestBuiltEnvironmentEntry.target_goal);
                    } else {
                        // If no latest entry found for Waste, set default values
                        setLatestBuiltEnvironmentPercentageTarget(0);
                        setLatestBuiltEnvironmentTargetGoal("");
                    }
                    // Set the latest percentage target to state
                    if (latestWasteTargetsEntry) {
                        setLatestWastePercentageTarget(latestWasteTargetsEntry.percentage_target);
                        setLatestWasteTargetGoal(latestWasteTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Waste, set default values
                        setLatestWastePercentageTarget(0);
                        setLatestWasteTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTransportTargetsEntry) {
                        setLatestTransportPercentageTarget(latestTransportTargetsEntry.percentage_target);
                        setLatestTransportTargetGoal(latestTransportTargetsEntry.target_goal);
                    }else {
                        // If no latest entry found for Transport, set default values
                        setLatestTransportTargetGoal(0);
                        setLatestTransportTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestLandUseTargetsEntry) {
                        setLatestLandUsePercentageTarget(latestLandUseTargetsEntry.percentage_target);
                        setLatestLandUseTargetGoal(latestLandUseTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Land Use, set default values
                        setLatestLandUsePercentageTarget(0);
                        setLatestLandUseTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalEnergyEmissionsPercentageTarget(latestTotalEnergyEmissionsTargetsEntry.percentage_target);
                        setLatestTotalEnergyEmissionsTargetGoal(latestTotalEnergyEmissionsTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Energy Emissions, set default values
                        setLatestTotalEnergyEmissionsPercentageTarget(0);
                        setLatestTotalEnergyEmissionsTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalWaterSupplyPercentageTarget(latestTotalWaterSupplyTargetsEntry.percentage_target);
                        setLatestTotalWaterSupplyTargetGoal(latestTotalWaterSupplyTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Water Supply, set default values
                        setLatestTotalWaterSupplyPercentageTarget(0);
                        setLatestTotalWaterSupplyTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalWaterTreatmentPercentageTarget(latestTotalWaterTreatmentTargetsEntry.percentage_target);
                        setLatestTotalWaterTreatmentTargetGoal(latestTotalWaterTreatmentTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Water Treatment, set default values
                        setLatestTotalWaterTreatmentPercentageTarget(0);
                        setLatestTotalWaterTreatmentTargetGoal("");
                    }

                    
                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalTonnesPercentageTarget(latestTotalTonnesTargetsEntry.percentage_target);
                        setLatestTotalTonnesTargetGoal(latestTotalTonnesTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Tonnes, set default values
                        setLatestTotalTonnesPercentageTarget(0);
                        setLatestTotalTonnesTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalRecyclePercentageTarget(latestTotalRecycleTargetsEntry.percentage_target);
                        setLatestTotalRecycleTargetGoal(latestTotalRecycleTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Recycle, set default values
                        setLatestTotalRecyclePercentageTarget(0);
                        setLatestTotalRecycleTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalIncineratePercentageTarget(latestTotalIncinerateTargetsEntry.percentage_target);
                        setLatestTotalIncinerateTargetGoal(latestTotalIncinerateTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Incinerate, set default values
                        setLatestTotalIncineratePercentageTarget(0);
                        setLatestTotalIncinerateTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalMilesPercentageTarget(latestTotalMilesTargetsEntry.percentage_target);
                        setLatestTotalMilesTargetGoal(latestTotalMilesTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Incinerate, set default values
                        setLatestTotalMilesPercentageTarget(0);
                        setLatestTotalMilesTargetGoal("");
                    }

                    // Set the latest percentage target to state
                    if (latestTotalEnergyEmissionsTargetsEntry) {
                        setLatestTotalTransportEmissionsPercentageTarget(latestTotalTransportEmissionsTargetsEntry.percentage_target);
                        setLatestTotalTransportEmissionsTargetGoal(latestTotalTransportEmissionsTargetsEntry.target_goal);
                    } else {
                        // If no latest entry found for Incinerate, set default values
                        setLatestTotalTransportEmissionsPercentageTarget(0);
                        setLatestTotalTransportEmissionsTargetGoal("");
                    }
                }
            })
        }
    
        // Function to get the latest entry from an array of targets
        const getLatestEntry = (targets, currentDate) => {
            if (targets.length > 0) {
                return targets.reduce((prev, current) => {
                    const prevStartDate = new Date(prev.start_date);
                    const prevEndDate = new Date(prev.end_date);
                    const currentStartDate = new Date(current.start_date);
                    const currentEndDate = new Date(current.end_date);
    
                    // Check if the current date falls between the start and end dates of the target
                    const isCurrentDateInRange = currentDate >= prevStartDate && currentDate <= prevEndDate &&
                                                 currentDate >= currentStartDate && currentDate <= currentEndDate;
    
                    // Return the entry with the later end date if both are valid for the current date
                    return isCurrentDateInRange && (currentEndDate > prevEndDate) ? current : prev;
                });
            } else {
                // If no entries found, return null
                return null;
            }
        };
        getCompanyTargets();
    }, []);

    const filterList = [
        "Select",
        "per 100M2",
        "per Student",
    ];

    const topFilterList = [
        "Select",
        5,
        10,
        15,
        20,
        25,
        30
    ];
    const associatedOfficeList = [
        "All",
        "Antrim",
        "Armagh",
        "Belfast",
        "Dundonald",
        "Omagh"
    ];

    const facilityList = [
        "All",
        "Nursery",
        "Pre-Primary",
        "Primary",
        "Post-Primary"
    ];

    const locationList = [
        "All",
        "Rural",
        "Urban"
    ];

    const buildingTypeList = [
        "All",
        "Dining Kitchen",
        "Distribution Canteen",
        "No Cooking",
        "Production Kitchen"
    ];

    const labelContent = (props) => {
        let formatedNumber = Number(props.dataItem.Amount).toLocaleString(undefined, {
            // style: "percent",
            minimumFractionDigits: 2,
        });
        let contribNumber = Number(props.dataItem.Amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return `${props.dataItem.Source}:\r\n(${formatedNumber} kgCO2e)`;
    };

    const urbanSeriesTip = ({ point }) => (
        <span>
            <b>Urban School: {point.value}</b>
        </span>
    );

    const ruralSeriesTip = ({ point }) => (
        <span>
            <b>Rural School: {point.value}</b>
        </span>
    );

    const SharedTooltip = (props) => {
        const { category, points } = props;
        return (
            <div>
                <div>{category}</div>
                {points.map((point, i) => (
                    <div key={i}>
                        {point.series.name} : {point.value}
                    </div>
                ))}
            </div>
        );
    };
    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const onUpdateRegion = () => {
        // if($('#regionSelectList').val == "All") {
        //     const cats = ["Antrim", "Armagh", "Belfast", "Dundonald", "Omagh"];
        //     const allvalues = [1002, 2833, 8399, 2929, 929];
        // } else {
        //     const cats = ["Sample School"];
        //     const allvalues = [929];
        // }
        // console.log('clicked');
    }


    // Update all values on change filter

    const reportboxstyle = {
        border: "3px solid #ff6358",
        borderRadius: "10px",
        padding: "10px",
        minHeight: "255px"
    }

    return (
        <div className="content">
            <div className="selectionBar">
                <Grid>
                    <Cell small={12} medium={6} large={6}>
                        <Grid>
                            <Cell small={12} medium={3} large={3}>
                                <div className="padRegion">
                                    Associated EA Offices:
                                    <div className="padRegion">
                                        <DropDownList
                                            style={{
                                                width: "200px",
                                            }}
                                            data={associatedOfficeList}
                                            defaultValue="All"
                                            onChange={onUpdateRegion}
                                            id="regionSelectList"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <div className="padRegion">
                                    Type of Facility:
                                    <div className="padRegion">
                                        <DropDownList
                                            style={{
                                                width: "200px",
                                            }}
                                            data={facilityList}
                                            defaultValue="All"
                                            onChange={onUpdateRegion}
                                            id="regionSelectList"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <div className="padRegion">
                                    Location:
                                    <div className="padRegion">
                                        <DropDownList
                                            style={{
                                                width: "200px",
                                            }}
                                            data={locationList}
                                            defaultValue="All"
                                            onChange={onUpdateRegion}
                                            id="regionSelectList"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <div className="padRegion">
                                    Building Type:
                                    <div className="padRegion">
                                        <DropDownList
                                            style={{
                                                width: "200px",
                                            }}
                                            data={buildingTypeList}
                                            defaultValue="All"
                                            onChange={onUpdateRegion}
                                            id="regionSelectList"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </Cell>
                        </Grid>
                    </Cell>


                    <Cell small={12} medium={6} large={6}>
                        <Grid>
                            <Cell small={12} medium={4} large={4}>
                                <div className="padRegion padAlignRight">
                                    <p>Start and End Dates:</p>
                                </div>
                            </Cell>
                            <Cell small={12} medium={4} large={4}>
                                <DatePicker defaultValue={new Date(2023,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" disabled={true} />
                            </Cell>
                            <Cell small={12} medium={4} large={4}>
                                <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" disabled={true} />
                            </Cell>

                        </Grid>
                    </Cell>
                </Grid>
            </div>

            <div className="selectionBar">
                <Grid>
                    <Cell small={6} medium={3} large={3}>
                        <div className="padRegion" align={"right"}>
                            Normalization Filter
                        </div>
                    </Cell>
                    <Cell small={6} medium={3} large={3}>
                        <div className="padRegion">
                            <DropDownList
                                style={{
                                    width: "200px",
                                }}
                                data={filterList}
                                defaultValue="Select"
                                onChange={(e) => handleFilterChange(e)}
                                id="filterSelectList"
                            />
                        </div>
                    </Cell>
                </Grid>
            </div>
            { filterMsg ?
                <div className="dashContainerHeader">
                    {filterMsg}
                </div> : '' }
            <div className="dashboard">
                <div className="dashContainer">

                    <Grid className="display">
                        <Cell small={12} medium={12} large={12}>
                            <TabStrip selected={selected} onSelect={handleSelect}>
                                <TabStripTab title="Comparitive">
                                    <Grid>
                                        <Cell small={12} medium={6} large={6}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by Scope across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={scopeLabels}>
                                                        <ChartCategoryAxisTitle text="Energy Sources" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>

                                                <ChartSeries>
                                                    {scopeChart.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={6} large={6}>
                                                <ExcelExport data={scopeData} ref={_export}>
                                                    <DataGrid
                                                        style={{
                                                            height: "400px",
                                                        }}
                                                        data={orderBy(scopeData, sort)}
                                                        sortable={true}
                                                        sort={sort}
                                                        onSortChange={(e) => {
                                                            setSort(e.sort);
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            <button
                                                                title="Export Excel"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={excelExport}
                                                            >
                                                                Export to Excel
                                                            </button>
                                                        </Toolbar>
                                                        <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                        <Column field="Source" title="Source" />
                                                        <Column field="Year" title="Year" />
                                                        <Column field="KGCO2E" title="kgco2e" format="{0:n2}" />
                                                        <Column field="Unit" title="Unit" />
                                                    </DataGrid>
                                                </ExcelExport>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={6} large={6}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by School Transport across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={transportLabels}>
                                                        <ChartCategoryAxisTitle text="Transport Emissions" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    {transportData.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={6} large={6}>
                                            <ExcelExport data={transportTableData} ref={_export}>
                                                    <DataGrid
                                                        style={{
                                                            height: "200px",
                                                        }}
                                                        data={orderBy(transportTableData, sort)}
                                                        sortable={true}
                                                        sort={sort}
                                                        onSortChange={(e) => {
                                                            setSort(e.sort);
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            <button
                                                                title="Export Excel"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={excelExport}
                                                            >
                                                                Export to Excel
                                                            </button>
                                                        </Toolbar>
                                                        <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                        <Column field="Source" title="Source" />
                                                        <Column field="Year" title="Year" />
                                                        <Column field="Miles" title="Total Miles" format="{0:n2}" />
                                                        <Column field="Km" title="Total Km" format="{0:n2}" />
                                                        <Column field="KGCO2E" title="kgco2e" format="{0:n2}" />
                                                        <Column field="Unit" title="Unit" />
                                                    </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by Sub-Scope across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={subScopeLabels}>
                                                        <ChartCategoryAxisTitle text="Energy Sources" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    {subScopeChart.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={subScopeData} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(subScopeData, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                    <Column field="Source" title="Source" />
                                                    <Column field="Year" title="Year" />
                                                    <Column field="KGCO2E" title="kgco2e" format="{0:n2}" />
                                                    <Column field="Unit" title="Unit" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </TabStripTab>
                                <TabStripTab title="Estate Overview">
                                    <Grid>
                                        <Cell small={12} medium={6} large={3}>
                                            <div style={reportboxstyle}>
                                            <Grid>
                                                <Cell small={12} medium={12} large={12}>
                                                    <h5 style={{color: "#ff6358"}}>Built Environment</h5>
                                                </Cell>
                                                <Cell small={12} medium={12} large={12}>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Total Energy Emissions: </span> {formatNumber(energyEmissionsTile,'n2')} kgco2e</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Total Energy Emissions Target: </span>
                                                    {latestTotalEnergyEmissionsTargetGoal ?
                                                        (latestTotalEnergyEmissionsTargetGoal === "Reduction" ?
                                                            (energyEmissionsTile ? formatNumber(parseNumber(energyEmissionsTile * (100 - latestTotalEnergyEmissionsPercentageTarget) / 100), 'n2') : 0)
                                                            :
                                                            (latestTotalEnergyEmissionsTargetGoal === "Increase" ?
                                                                (energyEmissionsTile ? formatNumber(energyEmissionsTile + (energyEmissionsTile * (latestTotalEnergyEmissionsPercentageTarget) / 100), 'n2') : 0)
                                                                :
                                                                (energyEmissionsTile ?? 0)
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (energyEmissionsTile ? formatNumber(energyEmissionsTile * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2') : 0)
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (energyEmissionsTile ? formatNumber(energyEmissionsTile + (energyEmissionsTile * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2') : 0)
                                                                :
                                                                (energyEmissionsTile ?? 0)
                                                            )
                                                        )
                                                    } kgco2e</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Water Supply: </span>833,190 M3 (124,145.31 kgco2e / 124.145 tco2e)</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Water Supply Target: </span>
                                                    {latestTotalWaterSupplyTargetGoal ?
                                                        (latestTotalWaterSupplyTargetGoal === "Reduction" ?
                                                            (formatNumber(833190 * (100 - latestTotalWaterSupplyPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterSupplyTargetGoal === "Increase" ?
                                                                (formatNumber(833190 + (833190 * (latestTotalWaterSupplyPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(833190 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(833190 + (833190 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } M3 (
                                                    {latestTotalWaterSupplyTargetGoal ?
                                                        (latestTotalWaterSupplyTargetGoal === "Reduction" ?
                                                            (formatNumber(124145.31 * (100 - latestTotalWaterSupplyPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterSupplyTargetGoal === "Increase" ?
                                                                (formatNumber(124145.31 + (124145.31 * (latestTotalWaterSupplyPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(124145.31 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(124145.31 + (124145.31 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } kgco2e
                                                    {latestTotalWaterSupplyTargetGoal ?
                                                        (latestTotalWaterSupplyTargetGoal === "Reduction" ?
                                                            (formatNumber(124.145 * (100 - latestTotalWaterSupplyPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterSupplyTargetGoal === "Increase" ?
                                                                (formatNumber(124.145 + (124.145 * (latestTotalWaterSupplyPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(124.145 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(124.145 + (124.145 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } / tco2e)</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Water Treatment: </span>791,530.00 M3 (215,296.16 kgco2e / 215.296 tco2e)</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Water Treatment Target: </span>
                                                    {latestTotalWaterTreatmentTargetGoal ?
                                                        (latestTotalWaterTreatmentTargetGoal === "Reduction" ?
                                                            (formatNumber(791530 * (100 - latestTotalWaterTreatmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterTreatmentTargetGoal === "Increase" ?
                                                                (formatNumber(791530 + (791530 * (latestTotalWaterTreatmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(791530 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(791530 + (791530 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } M3 (
                                                    {latestTotalWaterTreatmentTargetGoal ?
                                                        (latestTotalWaterTreatmentTargetGoal === "Reduction" ?
                                                            (formatNumber(215296.16 * (100 - latestTotalWaterTreatmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterTreatmentTargetGoal === "Increase" ?
                                                                (formatNumber(215296.16 + (215296.16 * (latestTotalWaterTreatmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(215296.16 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(215296.16 + (215296.16 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } kgco2e
                                                    {latestTotalWaterTreatmentTargetGoal ?
                                                        (latestTotalWaterTreatmentTargetGoal === "Reduction" ?
                                                            (formatNumber(215.296 * (100 - latestTotalWaterTreatmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestTotalWaterTreatmentTargetGoal === "Increase" ?
                                                                (formatNumber(215.296 + (215.296 * (latestTotalWaterTreatmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                        :
                                                        (latestBuiltEnvironmentTargetGoal === "Reduction" ?
                                                            (formatNumber(215.296 * (100 - latestBuiltEnvironmentPercentageTarget) / 100, 'n2'))
                                                            :
                                                            (latestBuiltEnvironmentTargetGoal === "Increase" ?
                                                                (formatNumber(215.296 + (215.296 * (latestBuiltEnvironmentPercentageTarget) / 100), 'n2'))
                                                                :
                                                                0
                                                            )
                                                        )
                                                    } / tco2e)</p>
                                                    <p><span style={{color: "#ff6358", weight: "bold"}}>Target Pecentage : </span>{latestBuiltEnvironmentPercentageTarget ? `${latestBuiltEnvironmentTargetGoal} by ${latestBuiltEnvironmentPercentageTarget}%` : "No target set"}</p>
                                                </Cell>
                                            </Grid>
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={6} large={3}>
                                            <div style={reportboxstyle}>
                                                <Grid>
                                                    <Cell small={12} medium={12} large={12}>
                                                        <h5 style={{color: "#ff6358"}}>Waste</h5>
                                                    </Cell>
                                                    <Cell small={12} medium={12} large={12}>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Tonnes: </span> {formatNumber(totalWasteSummary['amount']  ?? 0,'n2')}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total KgCO2e: </span>{formatNumber(totalWasteSummary['kgco2e'] ?? 0,'n2')}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total KgCO2: </span>{formatNumber(totalWasteSummary['kgco2'] ?? 0,'n2')}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Miles: </span>{formatNumber(totalWasteSummary['miles'] ?? 0,'n2')}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Tonnes Target: </span>
                                                            {latestTotalTonnesTargetGoal ?
                                                                (latestTotalTonnesTargetGoal === "Reduction" ?
                                                                    (totalSummaryTonnage ? formatNumber(totalSummaryTonnage * (100 - latestTotalTonnesPercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTotalTonnesTargetGoal === "Increase" ?
                                                                        (totalSummaryTonnage ? formatNumber((totalSummaryTonnage + (totalSummaryTonnage * (latestTotalTonnesPercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryTonnage ?? 0)
                                                                    )
                                                                )
                                                                :
                                                                (latestWasteTargetGoal === "Reduction" ?
                                                                    (totalSummaryTonnage ? formatNumber(totalSummaryTonnage * (100 - latestWastePercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestWasteTargetGoal === "Increase" ?
                                                                        (totalSummaryTonnage ? (totalSummaryTonnage + formatNumber((totalSummaryTonnage * (latestWastePercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryTonnage ?? 0)
                                                                    )
                                                                )
                                                            }
                                                        </p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Recycle KGCO2e: </span>{totalSummaryProcessEmissions['total_recycle'] ?? 0}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Recycle KGCO2e Target: </span>
                                                            {latestTotalRecycleTargetGoal ?
                                                                (latestTotalRecycleTargetGoal === "Reduction" ?
                                                                    (totalSummaryProcessEmissions['total_recycle'] ? formatNumber(totalSummaryProcessEmissions['total_recycle'] * (100 - latestTotalRecyclePercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTotalRecycleTargetGoal === "Increase" ?
                                                                        (totalSummaryProcessEmissions['total_recycle'] ? formatNumber((totalSummaryProcessEmissions['total_recycle'] + (totalSummaryProcessEmissions['total_recycle'] * (latestTotalRecyclePercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryProcessEmissions['total_recycle'] ?? 0)
                                                                    )
                                                                )
                                                                :
                                                                (latestWasteTargetGoal === "Reduction" ?
                                                                    (totalSummaryProcessEmissions['total_recycle'] ? formatNumber(totalSummaryProcessEmissions['total_recycle'] * (100 - latestWastePercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestWasteTargetGoal === "Increase" ?
                                                                        (totalSummaryProcessEmissions['total_recycle'] ? formatNumber((totalSummaryProcessEmissions['total_recycle'] + (totalSummaryProcessEmissions['total_recycle'] * (latestWastePercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryProcessEmissions['total_recycle'] ?? 0)
                                                                    )
                                                                )
                                                            }
                                                        </p>
                                                        <p><span style={{color: "#ff6358"}}>Total Incinerate KGCO2e: </span>{totalSummaryProcessEmissions['total_incinerate'] ?? 0}</p>
                                                        <p><span style={{ color: "#ff6358"}}>Total Incinerate KGCO2e Target: </span>
                                                            {latestTotalIncinerateTargetGoal ?
                                                                (latestTotalIncinerateTargetGoal === "Reduction" ?
                                                                    (totalSummaryProcessEmissions['total_incinerate'] ? formatNumber(totalSummaryProcessEmissions['total_incinerate'] * (100 - latestTotalIncineratePercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTotalIncinerateTargetGoal === "Increase" ?
                                                                        (totalSummaryProcessEmissions['total_incinerate'] ? formatNumber((totalSummaryProcessEmissions['total_incinerate'] + (totalSummaryProcessEmissions['total_incinerate'] * (latestTotalIncineratePercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryProcessEmissions['total_incinerate'] ?? 0)
                                                                    )
                                                                )
                                                                :
                                                                (latestWasteTargetGoal === "Reduction" ?
                                                                    (totalSummaryProcessEmissions['total_incinerate'] ? formatNumber(totalSummaryProcessEmissions['total_incinerate'] * (100 - latestWastePercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestWasteTargetGoal === "Increase" ?
                                                                        (totalSummaryProcessEmissions['total_incinerate'] ? formatNumber((totalSummaryProcessEmissions['total_incinerate'] + (totalSummaryProcessEmissions['total_incinerate'] * (latestWastePercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (totalSummaryProcessEmissions['total_incinerate'] ?? 0)
                                                                    )
                                                                )
                                                            }
                                                        </p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Target Pecentage : </span>{latestWastePercentageTarget ? `${latestWasteTargetGoal} by ${latestWastePercentageTarget}%` : "No target set"}</p>
                                                    </Cell>
                                                </Grid>
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={6} large={3}>
                                            <div style={reportboxstyle}>
                                                <Grid>
                                                    <Cell small={12} medium={12} large={12}>
                                                        <h5 style={{color: "#ff6358"}}>Transport</h5>
                                                    </Cell>
                                                    <Cell small={12} medium={12} large={12}>

                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Miles: </span> {formatNumber(transportMilesTile,'n2')} Miles</p>
                                                        <p><span style={{ color: "#ff6358"}}>Total Miles Target: </span>
                                                            {latestTotalMilesTargetGoal ?
                                                                (latestTotalMilesTargetGoal === "Reduction" ?
                                                                    (transportMilesTile ? formatNumber(transportMilesTile * (100 - latestTotalMilesPercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTotalMilesTargetGoal === "Increase" ?
                                                                        (transportMilesTile ? formatNumber((transportMilesTile + (transportMilesTile * (latestTotalMilesPercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (transportMilesTile ?? 0)
                                                                    )
                                                                )
                                                                :
                                                                (latestTransportTargetGoal === "Reduction" ?
                                                                    (transportMilesTile ? formatNumber(transportMilesTile * (100 - latestTransportPercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTransportTargetGoal === "Increase" ?
                                                                        (transportMilesTile ? formatNumber((transportMilesTile + (transportMilesTile * (latestTransportPercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (transportMilesTile ?? 0)
                                                                    )
                                                                )
                                                            }
                                                        </p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Transport Emissions: </span> {formatNumber(transportEmissionsTile,'n2')} kgco2e</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Target Pecentage : </span>{latestTransportPercentageTarget ? `${latestTransportTargetGoal} by ${latestTransportPercentageTarget}%` : "No target set"}</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Transport Emissions Target: </span>
                                                            {latestTotalTransportEmissionsTargetGoal ?
                                                                (latestTotalTransportEmissionsTargetGoal === "Reduction" ?
                                                                    (transportEmissionsTile ? formatNumber(transportEmissionsTile * (100 - latestTotalTransportEmissionsPercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTotalTransportEmissionsTargetGoal === "Increase" ?
                                                                        (transportEmissionsTile ? formatNumber((transportEmissionsTile + (transportEmissionsTile * (latestTotalTransportEmissionsPercentageTarget) / 100)), 'n2' ) : 0)
                                                                        :
                                                                        (transportEmissionsTile ?? 0)
                                                                    )
                                                                )
                                                                :
                                                                (latestTransportTargetGoal === "Reduction" ?
                                                                    (transportEmissionsTile ? formatNumber(transportEmissionsTile * (100 - latestTransportPercentageTarget) / 100, 'n2') : 0)
                                                                    :
                                                                    (latestTransportTargetGoal === "Increase" ?
                                                                        (transportEmissionsTile ? formatNumber((transportEmissionsTile + (transportEmissionsTile * (latestTransportPercentageTarget) / 100)), 'n2') : 0)
                                                                        :
                                                                        (transportEmissionsTile ?? 0)
                                                                    )
                                                                )
                                                            }
                                                        </p>
                                                    </Cell>
                                                </Grid>
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={6} large={3}>
                                            <div style={reportboxstyle}>
                                                <Grid>
                                                    <Cell small={12} medium={12} large={12}>
                                                        <h5 style={{color: "#ff6358"}}>Land Use</h5>
                                                    </Cell>
                                                    <Cell small={12} medium={12} large={12}>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Total Logged Initiatives: </span> 0 Initiatives</p>
                                                        <p><span style={{color: "#ff6358", weight: "bold"}}>Target Pecentage : </span>{latestLandUsePercentageTarget ? `${latestLandUseTargetGoal} by ${latestLandUsePercentageTarget}%` : "No target set"}</p>
                                                    </Cell>
                                                </Grid>
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={12} large={5}>
                                            <Chart style={{height: 400}}>
                                                <ChartTitle text="kgCO2 generated across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartSeries>
                                                    <ChartSeriesItem
                                                        type="pie"
                                                        data={energyBreakdownData}
                                                        field="Amount"
                                                        categoryField="Source"
                                                        labels={{
                                                            visible: true,
                                                            content: labelContent,
                                                        }}
                                                        padding={80}
                                                    />
                                                </ChartSeries>
                                                <ChartLegend visible={false} />
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={12} large={7}>
                                            <ExcelExport data={energyBreakdownData} ref={_export}>
                                                    <DataGrid
                                                        style={{
                                                            height: "400px",
                                                        }}
                                                        data={orderBy(energyBreakdownData, sort)}
                                                        sortable={true}
                                                        sort={sort}
                                                        onSortChange={(e) => {
                                                            setSort(e.sort);
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            <button
                                                                title="Export Excel"
                                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                onClick={excelExport}
                                                            >
                                                                Export to Excel
                                                            </button>
                                                        </Toolbar>
                                                        <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                        <Column field="Source" title="Source" />
                                                        <Column field="Period" title="Year" />
                                                        <Column field="Amount" title="Amount (kgco2e)" format="{0:n2}" />
                                                        <Column field="Unit" title="Metric" />
                                                    </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={12} large={6}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by School Types across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={typesBreakdownLabels}>
                                                        <ChartCategoryAxisTitle text="School Categories" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    {typesBreakdownData.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                        <Cell small={12} medium={12} large={6}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by School Locations across the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={locationsBreakdownLabels}>
                                                        <ChartCategoryAxisTitle text="Location Labels" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    {locationsBreakdownData.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                        {/*<Cell small={12} medium={12} large={6}>*/}
                                        {/*    <Chart>*/}
                                        {/*        <ChartTooltip shared={true} render={sharedTooltipRender} />*/}
                                        {/*        <ChartTitle text="Kgco2e by School Kitchen Types across the EA Estate" />*/}
                                        {/*        <ChartLegend position="bottom" />*/}
                                        {/*        <ChartCategoryAxis>*/}
                                        {/*            <ChartCategoryAxisItem categories={energyCats}>*/}
                                        {/*                <ChartCategoryAxisTitle text="Kitchen Types" />*/}
                                        {/*            </ChartCategoryAxisItem>*/}
                                        {/*        </ChartCategoryAxis>*/}
                                        {/*        <ChartSeries>*/}
                                        {/*            {locatData.map((item, idx) => (*/}
                                        {/*                <ChartSeriesItem*/}
                                        {/*                    key={idx}*/}
                                        {/*                    type="column"*/}
                                        {/*                    tooltip={{*/}
                                        {/*                        visible: true,*/}
                                        {/*                    }}*/}
                                        {/*                    data={item.data}*/}
                                        {/*                    name={item.name}*/}
                                        {/*                    stack={false}*/}
                                        {/*                />*/}
                                        {/*            ))}*/}
                                        {/*        </ChartSeries>*/}
                                        {/*    </Chart>*/}
                                        {/*</Cell>*/}
                                        <Cell small={12} medium={12} large={12}>
                                            <Chart>
                                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                                <ChartTitle text="Kgco2e by School within the EA Estate" />
                                                <ChartLegend position="bottom" />
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem categories={schoolsBreakdownLabels}>
                                                        <ChartCategoryAxisTitle text="Schools" />
                                                    </ChartCategoryAxisItem>
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    {schoolsBreakdownData.map((item, idx) => (
                                                        <ChartSeriesItem
                                                            key={idx}
                                                            type="bar"
                                                            tooltip={{
                                                                visible: true,
                                                            }}
                                                            data={item.data}
                                                            name={item.name}
                                                            stack={false}
                                                        />
                                                    ))}
                                                </ChartSeries>
                                            </Chart>
                                        </Cell>
                                    </Grid>
                                </TabStripTab>
                                <TabStripTab title="% by Type">
                                    <Grid style={{width: "100%"}}>
                                        <Cell small={12} medium={12} large={12}><h3 style={{color: "#ff6358"}}>Top % Filterable by Total Energy Emissions </h3></Cell>
                                        <Cell small={12} medium={12} large={12}><i>** Kgco2e emission values as generated using yearly UK conversion factors.</i></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="padRegion" align="right">
                                                <span className="padRegion"> Top Percent Filter</span>
                                                <DropDownList
                                                    style={{
                                                        width: "200px",
                                                    }}
                                                    data={topFilterList}
                                                    defaultValue="Select"
                                                    value = {topFilterValue}
                                                    onChange={(e) => handleTopFilterChange(e)}
                                                    id="topfilterSelectList"
                                                />
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={schoolsTotalTopTwentyTable} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(schoolsTotalTopTwentyTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                    <Column field="Year" title="Year" />
                                                    <Column field="School" title="School" />
                                                    <Column field="KGCO2E" title="Amount Kgco2e" format="{0:n2}"/>
                                                    <Column field="Unit" title="Metric" />
                                                    <Column field="FloorArea" title="FloorArea" />
                                                    <Column field="Students" title="Students" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>

                                        <Cell small={12} medium={12} large={12}><h3 style={{color: "#ff6358"}}>Top % Filterable by Transport Vehicle Type </h3></Cell>
                                        <Cell small={12} medium={12} large={12}><i>** Kgco2e emission values as generated using Vehicle Type.</i></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={transportTotalTopTwentyTable} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(transportTotalTopTwentyTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                    <Column field="Year" title="Year" />
                                                    <Column field="Source" title="Vehicle Type" />
                                                    <Column field="KGCO2E" title="Amount Kgco2e" format="{0:n2}"/>
                                                    <Column field="Unit" title="Metric" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>

                                        <Cell small={12} medium={12} large={12}><hr/></Cell>
                                        <Cell small={12} medium={12} large={12}><h3 style={{color: "#ff6358"}}>Top % Filterable by Energy Source</h3></Cell>
                                        <Cell small={12} medium={12} large={12}><i>** Kgco2e emission values as generated using yearly UK conversion factors.</i></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={schoolsSubbedTopTwentyTable} ref={_export}>

                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(schoolsSubbedTopTwentyTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <GridNoRecords> { showLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                    <Column field="Source" title="School" />
                                                    <Column field="Year" title="Year" />
                                                    <Column field="Electric" title="Electric" format="{0:n2}" />
                                                    <Column field="Gas" title="Natural Gas" format="{0:n2}" />
                                                    <Column field="Lpg" title="LPG" format="{0:n2}" />
                                                    <Column field="Oil" title="Burning Oil (Kerosene)" format="{0:n2}" />
                                                    <Column field="Unit" title="Metric" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </TabStripTab>
                                <TabStripTab title="Conversions Information">
                                    <Grid>
                                        <Cell small={12} medium={12} large={12}><h3 style={{color: "#ff6358"}}>Conversion Factor UK</h3></Cell>
                                        <Cell small={12} medium={12} large={12}><i>** Sortable by year, type, or factor.</i></Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <ExcelExport data={conversionsData} ref={_export}>
                                                <DataGrid
                                                    style={{
                                                        height: "600px",
                                                    }}

                                                    data={filterBy(conversionsData, filter).slice(page.skip, page.take + page.skip)}
                                                    skip={page.skip}
                                                    take={page.take}
                                                    total={filterBy(conversionsData, filter).length}
                                                    pageable={{
                                                        buttonCount: 4,
                                                        pageSizes: [5, 10, 15, "All"],
                                                        pageSizeValue: pageSizeValue,
                                                    }}
                                                    onPageChange={pageChange}
                                                    filterable={true}
                                                    filter={filter}
                                                    onFilterChange={(e) => setFilter(e.filter)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={sortChange}
                                                >
                                                    <Toolbar>
                                                        <button
                                                            title="Export Excel"
                                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                            onClick={excelExport}
                                                        >
                                                            Export to Excel
                                                        </button>
                                                    </Toolbar>
                                                    <GridNoRecords> { conversionLoading ? 'Please Wait Data is Loading' : 'No Records Available' } </GridNoRecords>
                                                    <Column field="Source" title="Source" />
                                                    <Column field="Subsource" title="Sub-Source" />
                                                    <Column field="Scope" title="Scope" />
                                                    <Column field="Region" title="Region" />
                                                    <Column field="Year" title="Year" />
                                                    <Column field="ConversionFrom" title="Conversion From" />
                                                    <Column field="ConversionTo" title="Conversion To" />
                                                    <Column field="Factor" title="Factor" />
                                                </DataGrid>
                                            </ExcelExport>
                                        </Cell>
                                    </Grid>
                                </TabStripTab>
                            </TabStrip>
                        </Cell>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default Content