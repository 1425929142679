import {Link, useParams} from "react-router-dom";
import {Cell} from "react-foundation";
import * as React from "react";
const BiodiversityNavbar = () => {
    const params = useParams();
    const siteid = params['site'];

    return (
        <Cell small={12} medium={2} large={2}>
            <div className="energyNavigation">
                <ul>
                    <li>
                        <Link to={'/biodiversity/dashboard/' + siteid } className="nav-link">Biodiversity Dashboard</Link>
                    </li>
                    <li>
                        <Link to={'/biodiversity/status/' + siteid } className="nav-link">Biodiversity Status</Link>
                    </li>
                    <li>
                        <Link to={'/biodiversity/addinitative/' + siteid } className="nav-link">Add Initiative</Link>
                    </li>
                </ul>
                <hr/>

            </div>
        </Cell>
    )
}

export default BiodiversityNavbar