import * as React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    Sparkline,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartTitle,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem, ChartArea,
} from '@progress/kendo-react-charts';
import { formatNumber } from '@progress/kendo-intl';
export default function EnergyChart(props) {
    const categoryAxisMaxDivisions = 15;
    const valueAvg = props.chartData['0'] ? props.chartData['0']['avg']??0 : 0;
    const inActiveFilter = props.inActiveFilter?? false;
    return (
        <>
        { valueAvg>0 ?
            <div className="dashContainerHeader" >Avg Usage: {formatNumber(valueAvg, "n2") + ' ' +props.xaxisTitle}
                {props.floorArea > 0 ? <div>   Avg Usage per 100M2: {formatNumber((valueAvg/props.floorArea)*100, "n2") + ' ' +props.xaxisTitle} </div> : ''}
            </div>
            : ''}
            <Chart
                pannable={{
                    lock: "y",
                }}
                zoomable={{
                    mousewheel: {
                        lock: "y",
                    },
                    selection: {
                        lock: "y",
                    },
                }}
            >
                <ChartTitle text={props.title} />
                <ChartLegend position={props.legendPosition ?? "bottom"} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        maxDivisions={categoryAxisMaxDivisions}
                        labels={{
                            font: "10px Arial,Helvetica,sans-serif",
                            rotation: {
                                angle:  props.angle??'270'
                            }
                        }}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        title={{
                            text: props.xaxisTitle??'',
                            position: "top"
                        }}
                        labels={{
                            visible: true,
                            format: "#.00",
                        }}
                        name="Energy Value"
                    />
                </ChartValueAxis>
                {props.height ? <ChartArea  height={props.height} /> : ''}
                <ChartSeries>
                    {props.chartData?.map((item, idx) => (
                        <ChartSeriesItem
                            key={props.title + idx}
                            color={props.color??''}
                            type={props.type}
                            tooltip={{ visible: true }}
                            data={item.data}
                            name={item.name??props.yaxisTitle??''}
                            field="value" categoryField="category"
                            visible = {!(inActiveFilter && idx != 0)}
                        />
                    ))}
                </ChartSeries>
            </Chart>
        </>

    );
}