import * as React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    Sparkline,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartTitle,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem, ChartArea,
} from '@progress/kendo-react-charts';
import { formatNumber } from '@progress/kendo-intl';

function childChart(series) {
    if(series) {
        return series?.map((item, idx) => (
            <ChartSeriesItem
                key={item.name + idx}
                type="line"
                tooltip={{ visible: true }}
                data={item.data}
                name={item.name??''}
            />
        ))
    } else return false;

}
export default function PredictiveChart(props) {
    const categoryAxisMaxDivisions = 15;

    return (
            <Chart
                pannable={{
                    lock: "y",
                }}
                zoomable={{
                    mousewheel: {
                        lock: "y",
                    },
                    selection: {
                        lock: "y",
                    },
                }}
            >
                <ChartTitle text={props.title} />
                <ChartLegend position={props.legendPosition ?? "bottom"} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={props.categories}
                        maxDivisions={categoryAxisMaxDivisions}
                        labels={{
                            font: "10px Arial,Helvetica,sans-serif",
                            rotation: {
                                angle:  props.angle??'270'
                            }
                        }}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        title={{
                            text: props.xaxisTitle??'',
                            position: "top"
                        }}
                        labels={{
                            visible: true,
                            format: "#.00",
                        }}
                        name="Energy Value"
                    />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        key={props.name + Math.random()}
                        type="line"
                        tooltip={{ visible: true }}
                        data={props.chartData}
                        name={props.name??props.yaxisTitle??''}
                    />
                    {childChart(props.changeSeries??false)}
                </ChartSeries>
            </Chart>


    );
}