import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";

import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from "@progress/kendo-data-query";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import BiodiversityNavbar from "./Navbar";
import { QRCode } from "@progress/kendo-react-barcodes";
import { saveAs } from "@progress/kendo-file-saver";
import { Button } from "@progress/kendo-react-buttons";

const cardStyles = {
    width: "200px",
};
function BiodiversityDashboard() {

    const [BioData, setBioData] = useState([]);
    const [InitiativesData, setInitiativesData] = useState([]);

    const [siteName, setSiteName] = useState('');

    const params = useParams();
    const site = params['site'];

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const EditCommandCell = (props) => {
        console.log(props.dataItem);
        return (
            <td>
                <Link to={'/biodiversity/addinitative/' + site + '/' + props.dataItem.Id}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                >
                    Edit
                </Link>
            </td>
        );
    };

    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} />
    );

    const qrcode = React.createRef();

    const exportQRCode = () => {
        if (!qrcode.current) {
            return;
        }
        qrcode.current.exportImage().then((dataURI) => {
            saveAs(dataURI, "qrcode_biodiversity"+site+".png");
        });
    };

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            }).catch(function(error) {
                window.location.replace("/notfound")
            });
        }
        loadSite();

        const loadBiodiversity = async () => {
            axiosApi.get(
                '/pillar/2/biodiversity',
                {
                    params: {
                        site_id: site
                    }
                }
            ).then(function (response) {
                //console.log(response['data']['data']);
                if (response['data']['data']) {
                    setBioData(response['data']['data']);
                }
            }).catch(function(error) {
                window.location.replace("/sitelanding/"+site)
            });
        }
        loadBiodiversity();

        const loadInitiatives = async () => {
            axiosApi.get(
                '/pillar/2/biodiversity-initiatives',
                {
                    params: {
                        site_id: site
                    }
                }
            ).then(function (response) {
                let entries = response['data']['data'];
                let dataTable = [];
                if (entries.length > 0) {
                    for(let i=0;  i< entries.length; i++) {
                        let entry = entries[i];
                        dataTable.push({
                            "Id": entry['id'],
                            "Title": entry['title'],
                            "DateStarted": entry['date'],
                            "Description": entry['description'],
                            "Location" : entry['location']
                        })
                    }
                    setInitiativesData(dataTable);
                }

            });
        }
        loadInitiatives();

    },[site]);


    return (
        <Grid>
            <BiodiversityNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/siteLanding/' + site} className="linkBack">&#11164; Back to Site Dashboard</Link>
                            </div>
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Bio Diversity Summary -{ siteName }</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={6}>
                                    <div className="bio-status-box">
                                        <h5 className="k-text-bold k-text-primary ">GIS Report</h5>
                                        <div className="k-d-grid k-font-italic">
                                            <div>
                                                <div className="k-text-uppercase k-font-size-sm">Complete the Survey</div>
                                                <div className="k-font-size-sm k-mb-3">
                                                    <Link target="_blank" to="https://survey123.arcgis.com/share/743717aec42341f0899008891ac53f77">
                                                        Mapping Biodiversity Levels in Schools
                                                    </Link>
                                                </div>
                                                <div className="k-text-uppercase k-font-size-sm">View Results</div>
                                                <div className="k-font-size-sm  k-mb-3">
                                                    <Link target="_blank" to="https://survey123.arcgis.com/share/743717aec42341f0899008891ac53f77">
                                                        ArcGIS Web Application
                                                    </Link>
                                                </div>
                                                <div className="k-text-uppercase k-font-size-sm">Download the User guide</div>
                                                <div className="k-font-size-sm">
                                                    <a href={process.env.PUBLIC_URL + '/files/biodiversity/Biodiversity_Survey_User_Guide_FINAL.pdf'} download> User Guide</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </Cell>
                            <Cell small={12} medium={12} large={6}>
                                <Link to={'/biodiversity/status/' + site} className="k-link">
                                    <div className="bio-status-box">
                                        <h5 className="k-text-bold k-text-primary ">Biodiversity Status</h5>
                                        <div className="k-d-grid k-grid-cols-2 k-font-italic">
                                            <div>
                                                <div className="k-text-uppercase k-font-size-sm">Biodiversity Survey Produced</div>
                                                <div className="k-font-bold k-mb-2">{BioData['eco_survey'] === 1 ? 'Yes' : 'No'}</div>
                                                <div className="k-text-uppercase k-font-size-sm">Survey recommendations implemented?</div>
                                                <div className="k-font-bold  k-mb-2">{BioData['implemented_recommendations'] === 1 ? 'Yes' : 'No'}</div>
                                                <div className="k-text-uppercase k-font-size-sm"> Biodiversity Improvement (%)</div>
                                                <div className="k-font-bold">{BioData['net_biodiversity_gain_percentage']}</div>
                                            </div>
                                            <div>
                                                <div className="k-text-uppercase k-font-size-sm"> Biodiversity Champion selected?</div>
                                                <div className="k-font-bold  k-mb-2">{BioData['bio_champion'] === 1 ? 'Yes' : 'No'}</div>
                                                <div className="k-text-uppercase k-font-size-sm">Biodiversity Champion</div>
                                                <div className="k-font-bold">{BioData['person_name']}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Cell>
                            <Cell small={12} medium={12} large={12}>

                                <div className="k-card k-text-center" style={cardStyles}>
                                    <div className="k-card-header">
                                        <div className="k-card-title">Add BioDiversity Initiative</div>
                                    </div>
                                    <div className="k-card-body">
                                        <QRCode
                                            ref={qrcode}
                                            value={"https://siqfrontenddev.beingbuilt.uk/biodiversity/dashboard/"+site}
                                        />
                                    </div>
                                    <div className="k-card-actions k-card-actions-stretched">
                                          <span className="k-card-action">
                                            <Button
                                                themeColor={"secondary"}
                                                onClick={exportQRCode}
                                            >
                                              Export as Image
                                            </Button>
                                          </span>
                                    </div>
                                </div>
                            </Cell>


                            <Cell small={12} medium={12} large={12}>
                                <h4 className="k-text-primary"> Biodiversity Initiatives</h4>
                                <ExcelExport data={InitiativesData} ref={_export} fileName={siteName + '_biodiversity_initiatives.xlsx'} >
                                    <DataGrid
                                        style={{
                                            height: "600px",
                                        }}
                                        data={orderBy(InitiativesData, sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                    >
                                        <Toolbar>
                                            <Link to={'/biodiversity/addinitative/' + site}
                                                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                            >
                                                Add New
                                            </Link>

                                            <button
                                                title="Export Excel"
                                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                onClick={excelExport}
                                            >
                                                Export to Excel
                                            </button>


                                        </Toolbar>
                                        <Column field="Id" title="Id" />
                                        <Column field="Title" title="Title" />
                                        <Column field="DateStarted" title="DateStarted" />
                                        <Column field="Description" title="Description" />
                                        <Column field="Location" title="Location" />

                                    </DataGrid>
                                </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default BiodiversityDashboard;