import {Grid, Cell} from "react-foundation";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import axiosApi from "../api/axiosInstance";
import {useEffect, useState} from "react";
import * as React from "react";
import {FormatWasteEntries} from "./Waste/WasteHelper";
import {parseNumber} from "./form/format-helper";
import {formatNumber} from "@progress/kendo-intl";

function SiteLanding() {
    const navigate = useNavigate();
    const [kgcoData, setKgcoData] = useState([]);
    const [kgcoeData, setKgcoeData] = useState([]);
    const [kwhData, setKwhData] = useState([]);
    const [tcoData, setTcoData] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [bioId, setBioId] = useState(0);
    const [meterEntryList, setMeterEntryList] =useState([]);

    const[wasteM3, setWasteM3] = useState(0);
    const[wasteKg, setWasteKg] = useState(0);
    const[wasteTcoe, setWasteTcoe] = useState(0);



    const [transportKgcoe, setTransportKgcoe] = useState([]);
    const [transportTcoe, setTransportTcoe] = useState([]);
    const [transportDist, setTransportDist] = useState([]);


    const [totalWaterAmount, setTotalAmount] = useState(0);

    const [totalSummaryTonnage, setTotalSummaryTonnage] = useState([]);
    const [totalSummaryProcessEmissions, setTotalSummaryProcessEmissions] = useState([]);

    const siteid = useParams()['site'];

    const value = new Date();
    // const startDate = new Date("2022-09-01");
    // const endDate = new Date();


    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };



    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + siteid,
                ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }

        loadSite();
        const loadEntriesSection = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meter-entries/summary',
                {
                    params: {
                        site_id: siteid,
                        start_date: '2017-09-01',
                        end_date: '2024-12-31'
                    }
                }).then(function (response) {
                let kwhTotal = 0;
                let kgco2Total = 0;
                let kgco2eTotal = 0;
                let tco2eTotal = 0;
                if (response['data']['data']['summary']['overall'].length > 0) {
                    for (let i = 0; i < response['data']['data']['summary']['overall'].length; i++) {
                        let measurement = response['data']['data']['summary']['overall'][i]['measurement'];
                        let amount = parseNumber(response['data']['data']['summary']['overall'][i]['total_amount']);
                        if(measurement == 'kgCO2') {
                            kgco2Total = amount;
                        } else if(measurement == 'kgCO2e') {
                            kgco2eTotal = amount;
                            tco2eTotal = amount/1000;
                        } else if(measurement == 'Kilowatt Hour (Gross CV)') {
                            kwhTotal = amount
                        }
                    }
                }

                setKgcoData(kgco2Total);
                setKgcoeData(kgco2eTotal);
                setTcoData(tco2eTotal);
                setKwhData(kwhTotal);
            });
        }
        loadEntriesSection();

        const loadTransport = async () => {
            let tkgco2e = 0;
            let ttco = 0;
            let tmiles = 0;

            axiosApi.get(
            '/pillar/2/site-transports/total-summary/site',
            {
                params: {
                    site_id: siteid,
                    start_date: '2017-09-01',
                    end_date: '2024-12-31'
                }
            }).then(function (response) {
                let data = response['data']['data'];
                tmiles = (data['total_miles'])??0;
                if(data['overall'] && data['overall'].length > 0) {
                    for (let i = 0; i < data['overall'].length; i++) {
                        if(data['overall'][i]['measurement'] == 'kgCO2e') {
                            tkgco2e = parseFloat(data['overall'][i]['total_amount']).toFixed(3);
                            let tonne = (parseFloat(data['overall'][i]['total_amount']) / 1000);
                            ttco = tonne.toFixed(3);
                        }
                    }
                }
                setTransportDist(parseNumber(tmiles));
                setTransportKgcoe(parseNumber(tkgco2e));
                setTransportTcoe(parseNumber(ttco));
            });

        }
        loadTransport();

        const loadBiodiversity = async () => {
            axiosApi.get(
                '/pillar/2/biodiversity',
                {
                    params: {
                        site_id: siteid
                    }
                }
            ).then(function (response) {
                if (response['data']['data']['id']) {
                    setBioId(response['data']['data']['id']);
                }
            });
        }
        loadBiodiversity();
    }, [siteid]);

    const loadWaterMeterEntries = async () => {
        // Fetch meter entries
        const meterEntriesResponse = await axiosApi.get('/pillar/2/site-water-meter-entries', {
            params: {
                site_id: siteid,
                start_date: startDate,
                end_date: endDate
            }
        });
    
        // Extract meter entries data
        const meterEntriesData = meterEntriesResponse.data.data.list;
    
        if (meterEntriesData.length > 0) {
            // Extract unique meter IDs
            const uniqueMeterIds = [...new Set(meterEntriesData.map(entry => entry.meter_id))];
    
            // Fetch meter names for each unique meter ID
            const meterNamesPromises = uniqueMeterIds.map(async meterId => {
                const meterResponse = await axiosApi.get(`/pillar/2/site-water-meters/${meterId}`);
                return meterResponse.data.data.meter_name;
            });
    
            // Resolve all promises
            const meterNames = await Promise.all(meterNamesPromises);
    
            // Replace meter_ids with meter_names in meter entries data
            const meterEntriesWithNames = meterEntriesData.map(entry => ({
                ...entry,
                meter_name: meterNames[uniqueMeterIds.indexOf(entry.meter_id)]
            }));
    
            // Update state with meter entries including meter names
            setMeterEntryList(meterEntriesWithNames);
            // Calculate total amount
            const total = meterEntriesData.reduce((acc, entry) => acc + entry.amount, 0);
            setTotalAmount(parseNumber(total));
        }
    };
    loadWaterMeterEntries();



    useEffect(() => {

        const loadWasteUsageGraph = async () => {

            let url = '/pillar/2/site-waste-entries-summary';
            let params = {
                site_id: siteid,
                start_date: startDate,
                end_date: endDate,
            };

            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let data = response['data']['data'];

                let result = FormatWasteEntries(data, true);
                setTotalSummaryTonnage(result['total_summary']);
                setTotalSummaryProcessEmissions(result['total_summary_process_emissions']);
            });

        }
        loadWasteUsageGraph();
    },[startDate,endDate,siteid]);

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Pillar Reporting</h5>
                    <hr/>
                    <ul>
                        <li>
                            <Link to={'/sitelanding/' + siteid} className="nav-link">Environmental Management</Link>
                        </li>
                    </ul>
                    <hr/>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/landing/' + siteid} className="linkBack">&#11164; Back to Landing</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2018,9,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"}  name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Environmental Management Summary - { siteName }</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={3} large={3}>
                                <Link to={'/dashboard/energy/' + siteid}>
                                    <div className="quickSelectTab">
                                        <h4>Energy</h4>
                                        <hr/>
                                        <table className="statsTable">
                                            <tr>
                                                <td>Total kgCO2e:</td>
                                                <td>{ formatNumber(kgcoeData , 'n2') }</td>
                                            </tr>
                                            <tr>
                                                <td>Total tCO2e:</td>
                                                <td>{ formatNumber(tcoData , 'n2')}</td>
                                            </tr>
                                            <tr>
                                                <td>Total kWh:</td>
                                                <td>{ formatNumber(kwhData , 'n2')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Link>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <Link to={'/biodiversity/dashboard/' + siteid}>
                                    <div className="quickSelectTab">
                                        <h4>Biodiversity</h4>
                                        <hr/>
                                        <table className="statsTable">
                                            <tr>
                                                <td>Staus:</td>
                                                <td>{bioId===0 ? 'Pending' : 'Completed'}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Link>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <Link to={'/dashboard/transportLanding/' + siteid}>
                                    <div className="quickSelectTab">
                                        <h4>Transport</h4>
                                        <hr/>
                                        <table className="statsTable">
                                            <tr>
                                                <td>Total kgCO2e:</td>
                                                <td>{  formatNumber(transportKgcoe , 'n2') }</td>
                                            </tr>
                                            <tr>
                                                <td>Total tCO2e:</td>
                                                <td>{  formatNumber(transportTcoe , 'n2') }</td>
                                            </tr>
                                            <tr>
                                                <td>Total Miles:</td>
                                                <td>{  formatNumber(transportDist , 'n2') }</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Link>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <Link to={'/dashboard/waterLanding/' + siteid}>
                                    <div className="quickSelectTab">
                                        <h4>Water</h4>
                                        <hr/>
                                        <table className="statsTable">
                                            <tr>
                                                <td>Total :</td>
                                                <td>{  formatNumber(totalWaterAmount  , 'n2')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Link>
                            </Cell>
                            <Cell small={12} medium={3} large={3}>
                                <Link to={'/dashboard/wasteLanding/' + siteid}>
                                    <div className="quickSelectTab">
                                        <h4>Waste</h4>
                                        <hr/>
                                        <table className="statsTable">
                                            <tr>
                                                <td>Total Tonnes:</td>
                                                <p>{ formatNumber(totalSummaryTonnage['amount'] ?? 0 , 'n2') }</p>
                                            </tr>
                                            <tr>
                                                <td>Total KGCO2e:</td>
                                                <p>{ formatNumber(totalSummaryTonnage['kgco2e'] ?? 0  , 'n2')}</p>
                                            </tr>
                                            <tr>
                                                <td>Total KgCo2:</td>
                                                <td>{ formatNumber(totalSummaryTonnage['kgco2'] ?? 0  , 'n2')}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Link>
                            </Cell>

                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    )
}

export default SiteLanding;