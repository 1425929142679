function Help() {
    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="helpContainer">
                    <h1>Help & Support</h1>
                    <p>Help section details will be added soon, if the support required is more urgent, please contact <a href="mailto:hello@sustainiq.com">hello@sustainiq.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default Help;