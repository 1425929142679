import * as React from "react";
import {Grid, Cell} from "react-foundation";
import {Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar} from "@progress/kendo-react-grid";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import "hammerjs";
import {Form, Field, FormElement} from "@progress/kendo-react-form";
import {Button} from "@progress/kendo-react-buttons";
import {
    FormInput,
    FormMultiSelect, FormNumericTextBox, FormRadioGroup,
    FormSlider,
} from "../form/form-components"

import {filterBy, orderBy} from "@progress/kendo-data-query";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import EnergyChart from "../Energy/EnergyChart";
import {formatNumber} from "@progress/kendo-intl";
import {
    electricConversions,
    gasConversions,
    gasoilConversions,
    lpgConversions,
    transportConversions,
    burningConversions
} from "./predictive-data";
import PredictiveChart from "./PredictiveChart";
import {Checkbox, NumericTextBox} from "@progress/kendo-react-inputs";
import {parseNumber} from "../form/format-helper";

function PredectiveModel() {
    const [BaseDataTable, setBaseDataTable] = useState([]);
    const [BaseDataChart, setBaseDataChart] = useState([]);
    const [BaseDataTotals, setBaseDataTotals] = useState([]);

    const [PredictiveTotals, setPredictiveTotals] = useState([]);
    const [PredictiveDataChart, setPredictiveDataChart] = useState([]);
    const [PredictiveDataTable, setPredictiveDataTable] = useState([]);

    const [PredictiveChangeTotals, setPredictiveChangeTotals] = useState([]);
    const [PredictiveChangeDataChart, setPredictiveChangeDataChart] = useState([]);

    const [years, setYears] = useState(0);
    const [yearArray, setYearArray] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);
    const [energyTypes, setEnergyTypes] = useState([]);
    const[energyChangeType,setEnergyChangeType] = useState('');
    const[formCount,setFormCount] = useState(0);

    const [EnergyData, setEnergyData] = useState([]);

    const energyControlsData = [
        "Electricity",
        "Natural Gas",
        "Burning Oil",
        "LPG",
        "Gas Oil",
        "Transport",
    ]
    const conversions = {
        "Electricity": electricConversions,
        "Natural Gas": gasConversions,
        "LPG": lpgConversions,
        "Gas Oil": gasoilConversions,
        "Burning Oil": burningConversions,
        "Transport": transportConversions
    };
    const energyChanges = [];
    energyChanges['Electricity'] = {solarpanel: 0.05, windturbine:0.083, greenenergy:0};
    energyChanges['Natural Gas'] = {biopropane: 0.230, biomethane:0.230};
    energyChanges['Transport'] = {hybrid: 0.024, plugin:0};

    const initialSort = [
        {
            field: "eneryType",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const [startDate, setStartDate] = useState('2023-01-01');
    const [endDate, setEndDate] = useState('2023-12-31');


    //handle Tabstrip
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const monthNames = {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec'
    };
    const base_year = new Date().getFullYear() - 1;

    //Controls
    const [controlsForm, setControlsForm] = useState({})
    const [statusMsg, setStatusMsg] = useState('');
    const [changeControlsForm, setChangeControlsForm] = React.useState({});

    const handleControlsSubmit = (dataItem) => {

        let error = '';
        if (!dataItem['years']) error = 'Please select Year Range';
        if (!dataItem['energy_types'] || dataItem['energy_types'].length === 0) error = 'Please select Energy Types For Prediction';
        setStatusMsg(error);

        if (!error) {
            setYears(parseInt(dataItem['years']));
            setEnergyTypes(dataItem['energy_types'])
            setStatusMsg('Preditive tabs have been updated to meet the preferences outlined above.')
            setControlsForm(dataItem);
            setChangeControlsForm({});
            setPredictiveChangeTotals([]);
        }
        setSelected(2);
    };

    const onChangeControl = (event) => {
        setChangeControlsForm({
            ...changeControlsForm,
            [event.target.id]: event.target.value,
        });
    };

    const handleChangeSubmit = (e,type) => {
        setEnergyChangeType(type);
        setFormCount(formCount+1);
        e.preventDefault();
    };

    useEffect(() => {

        const loadBaseChart = async () => {
            let url = '/pillar/2/site-energy-meter-entries/predictive-summary';
            let params = {
                site_id: '1275,1265,165,721,810,667',
                start_date: startDate,
                end_date: endDate,
            };
            let energy_series = [];
            let energy_series_table = [];
            const energy_data = [];

            //get  data
            await axiosApi.get(
                url,
                {
                    params: params
                }
            ).then(function (response) {
                let data = response['data']['data'];

                //monthly entries
                let entries = data['summary']['by_energy_type_month'] ?? [];
                if (entries.length > 0) {
                    //let energy_series = [];
                    //let energy_series_table = [];
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i];

                        let name = entry['energy_type'];
                        let chart_data = [];
                        let table_data = [];
                        table_data['energyType'] = entry['energy_type'];
                        let total = data['summary']['total_kgco2e'];
                        let amounts = [];
                        for (let month in entry['list']) {
                            let monthEntry = entry['list'][month];
                            table_data[monthEntry['month']] = parseNumber(monthEntry['kgco2e_amount']);
                            amounts[month] = monthEntry['amount'];
                            chart_data.push({
                                value: parseNumber(monthEntry['kgco2e_amount']),
                                category: monthEntry['month']
                            })

                        }
                        table_data['Total'] = formatNumber(total, 'n2');
                        energy_data[name] = amounts;
                        energy_series.push({
                            name: name,
                            data: chart_data
                        });
                        energy_series_table.push(table_data);

                    }
                }

            });


            //get  data
            await axiosApi.get(
                '/pillar/2/site-transports/report-summary/workshop',
                {
                    params: {
                        start_date: startDate,
                        end_date: endDate
                    }
                }
            ).then(function (response) {
                let data = response['data'];
                let entries = [];

                entries = data['data']['summary']['by_month'] ?? [];
                if (entries.length > 0) {
                    let total = data['data']['summary']['overall'][0]['total_amount'] ?? 0;
                    let chart_data = [];
                    let table_data = [];
                    table_data['energyType'] = 'Transport';
                    let amounts = [];
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i];
                        let kgco2e = 0;
                        let ghg_emissions = entry['ghg_emissions'];

                        for (let j = 0; j < ghg_emissions.length; j++) {
                            let ghg_emission = ghg_emissions[j];
                            if (ghg_emission['measurement'] === 'kgCO2e') {
                                kgco2e = parseNumber(ghg_emission['amount']);
                            }
                        }
                        table_data[entry['month-name']] = kgco2e;
                        chart_data.push({
                            value: kgco2e,
                            category: entry['month-name']
                        })
                        amounts[entry['month']] = entry['kilometers'];

                    }
                    table_data['Total'] = formatNumber(total, 'n2');
                    energy_series.push({
                        name: 'Transport',
                        data: chart_data
                    });
                    energy_data['Transport'] = amounts;
                    energy_series_table.push(table_data);
                }

            });

            setBaseDataChart(energy_series);
            setBaseDataTable(energy_series_table);
            setEnergyData(energy_data);
        }

        loadBaseChart();
    }, [startDate, endDate]);

    useEffect(() => {
        const loadPredictiveChart = async () => {
            let year_values = [];
            let chart_series = [];
            let chart_categories = [];

            for (let i = 1; i <= years; i++) {
                year_values.push(base_year + i);
                for (let j = 0; j < months.length; j++) {
                    chart_categories.push(monthNames[months[j]] + '-' + (base_year + i))
                }
            }
            setYearArray(year_values);
            setChartCategories(chart_categories);

            let consumption_totals = [];
            let predictive_data_table = [];
            for (let energy_type of energyTypes) {
                for (let type in EnergyData) {
                    if (type == energy_type) {
                        let kgco2_data = []
                        let usage_data = [];

                        let energy_amounts = EnergyData[type];
                        let changed_amounts = [];
                        let data_table = [];
                        let totals_data = [];
                        let combined_total = 0;

                        totals_data['type'] = type;

                        for (let i = 1; i <= years; i++) {
                            let year = base_year + i;
                            let table_data = [];

                            table_data['year'] = year;

                            let conversion = conversions[type][year] ?? 1;
                            let total = 0;
                            for (let j = 0; j < months.length; j++) {

                                let month = (year - 1) + '-' + months[j];
                                let amount = 0;
                                if (i == 1) {
                                    amount = energy_amounts[month] ? (+energy_amounts[month] + (+energy_amounts[month] * 5 / 100)) * (0.95 + (i / 100)) : 0; //apply increase 5% * (0.95 tp 1.05)
                                } else {
                                    amount = changed_amounts[month] ? (+changed_amounts[month] + (+changed_amounts[month] * 5 / 100)) * (0.95 + (i / 100)) : 0; //apply increase 5% * (0.95 tp 1.05)
                                }

                                changed_amounts[year + '-' + months[j]] = amount;

                                let kgco2 = parseNumber(amount * conversion);
                                kgco2_data.push(kgco2);
                                usage_data.push(amount);

                                table_data[monthNames[months[j]]] = kgco2;
                                total = parseNumber(total + kgco2);

                            }
                            table_data['type'] = type;
                            table_data['Total'] = total;
                            data_table.push(table_data);

                            totals_data['total' + year] = total
                            combined_total += total;
                        }
                        totals_data['total'] = combined_total;
                        consumption_totals.push(totals_data);
                        chart_series.push({
                            name: type,
                            data: kgco2_data,
                            usage_data: usage_data,
                            table: data_table,
                            change_table: data_table
                        })
                        predictive_data_table[type] = data_table;
                    }
                }
            }
            setPredictiveDataChart(chart_series);
            setPredictiveChangeDataChart(chart_series);
            setPredictiveTotals(consumption_totals);
            setPredictiveDataTable(predictive_data_table);
        }
        loadPredictiveChart();
    }, [years, energyTypes]);

    useEffect(() => {
        const loadPredictiveChangeChart = async () => {
            let change_data = await getPredictiveChangeChartData;
            setPredictiveChangeDataChart(change_data);
        }
        loadPredictiveChangeChart();

    },[energyChangeType,formCount]);

    const getPredictiveChangeChartData = () => {
        let change_data = PredictiveChangeDataChart;

        let consumption_change_table = [];

        for (const index in change_data) {
            let item = change_data[index];
            if (item.name == energyChangeType) {
                let change_chart_series = [];
                let usage_data = item.usage_data;
                let kgco2_data = item.data;
                let conversions = energyChanges[item.name];
                for (let changeType in conversions) {
                    if (changeControlsForm[changeType + 'perc']) {

                        let perc = changeControlsForm[changeType + 'perc'];
                        let conversion = conversions[changeType];
                        if (perc > 0) {
                            let kgco2_change= [];

                            for (let i = 0; i < chartCategories.length; i++) {
                                kgco2_change[i] = parseNumber((kgco2_data[i] * (100-perc) / 100) +  ((usage_data[i]*perc/100) * conversion));
                            }
                            change_chart_series.push({
                                name: changeType,
                                data: kgco2_change,
                                percentage:  perc
                            })
                        }
                    }
                }
                //data[index]['table'] = table_data;
                change_data[index]['change_series'] = change_chart_series;
            }

            // Prepare datatable and consumption totals

            let change_table_data = [];

            for (let base_row of PredictiveDataTable[item.name] ) {
                change_table_data.push(base_row);
            }

            let change_series = change_data[index]['change_series'] ?? [];

            for (let change_item of change_series) {
                let change_table_row = [];
                let consumption_change_table_row = [];
                let change_combined_total = 0;
                let change_total_year = 0;

                consumption_change_table_row['type'] = item.name+'-'+change_item['name']+' ('+change_item['percentage']+'%)';

                for (let i = 0; i < chartCategories.length; i++) {
                    let month = chartCategories[i].slice(0, 3);
                    let year = chartCategories[i].slice(4, 8)

                    change_table_row[month] = change_item['data'][i];
                    change_total_year += change_item['data'][i];

                    if ((i + 1) % 12 === 0) {
                        change_table_row['year'] = year
                        change_table_row['type'] = (change_item['name'])
                        change_table_row['Total'] = parseNumber(change_total_year);
                        change_table_data.push(change_table_row);
                        change_table_row = [];

                        consumption_change_table_row['total' + year] = parseNumber(change_total_year)
                        change_combined_total += change_total_year;
                        change_total_year = 0;

                    }
                }
                consumption_change_table_row['total'] = change_combined_total;
                consumption_change_table.push(consumption_change_table_row);
            }


            change_data[index]['change_table'] = change_table_data;

        }
        setPredictiveChangeTotals(consumption_change_table);

        return change_data;
    }

    return (
        <Grid>
            <Cell small={12} medium={12} large={12}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">

                            </div>
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashboard">
                        <div className="dashContainer">
                            <div className="dashContainerHeader"><strong>Predictive Modelling Tool</strong></div>
                            <Grid>
                                <TabStrip selected={selected} onSelect={handleSelect}>
                                    <TabStripTab title="Baseline" key="tab1">
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="dashContainerHeader">
                                                Baseline 2023 for Predictive Modelling
                                            </div>
                                            <EnergyChart
                                                chartData={BaseDataChart}
                                                title="KGCO2e by Source (Scope1 and Scope2)"
                                                xaxisTitle="KgCO2e"
                                                type="line"
                                            />
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Toolbar>
                                                <h4>KgCO2e Year to Date 2023</h4>
                                            </Toolbar>
                                            <DataGrid
                                                data={orderBy(BaseDataTable, sort)}
                                                sortable={true}
                                                sort={sort}
                                                onSortChange={(e) => {
                                                    setSort(e.sort);
                                                }}
                                            >
                                                <Column field="energyType" title="Energy Source"/>

                                                <Column field="Jan-2023" title="Jan-2023" format="{0:n2}"/>
                                                <Column field="Feb-2023" title="Feb-2023" format="{0:n2}"/>
                                                <Column field="Mar-2023" title="Mar-2023" format="{0:n2}"/>
                                                <Column field="Apr-2023" title="Apr-2023" format="{0:n2}"/>
                                                <Column field="May-2023" title="May-2023" format="{0:n2}"/>
                                                <Column field="Jun-2023" title="Jun-2023" format="{0:n2}"/>
                                                <Column field="Jul-2023" title="Jul-2023" format="{0:n2}"/>
                                                <Column field="Aug-2023" title="Aug-2023" format="{0:n2}"/>
                                                <Column field="Sep-2023" title="Sep-2023" format="{0:n2}"/>
                                                <Column field="Oct-2023" title="Oct-2023" format="{0:n2}"/>
                                                <Column field="Nov-2023" title="Nov-2023" format="{0:n2}"/>
                                                <Column field="Dec-2023" title="Dec-2023" format="{0:n2}"/>
                                                <Column field="Total" title="Total" format="{0:n2}"/>
                                            </DataGrid>
                                        </Cell>
                                    </TabStripTab>
                                    <TabStripTab title="Controls" key="tab2">
                                        <div className="chart-tab-width">
                                            <Cell small={12} medium={12} large={12}>
                                                <div className="dashContainerHeader"><strong>Controls and
                                                    Settings</strong></div>
                                                <Form
                                                    initialValues={controlsForm}
                                                    id={"controls-form"}
                                                    key={"form1"}
                                                    onSubmit={handleControlsSubmit}
                                                    render={(formRenderProps) => (
                                                        <FormElement
                                                            style={{
                                                                width: 400,
                                                            }}
                                                        >
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <legend className={"k-form-legend"}>
                                                                    Controls
                                                                </legend>

                                                                <Field
                                                                    id={"energy_types"}
                                                                    name={"energy_types"}
                                                                    label={"Inclusions for Prediction"}
                                                                    hint={"Please select the items you wish to review, and allocate prediction types."}
                                                                    component={FormMultiSelect}
                                                                    data={energyControlsData}
                                                                    placeholder="Please select Energy Type"
                                                                />
                                                                <Field
                                                                    id={"years"}
                                                                    name={"years"}
                                                                    label={"Year range for Prediction"}
                                                                    hint={"Please select the year range for predictive results."}
                                                                    min={0}
                                                                    max={10}
                                                                    component={FormSlider}
                                                                    step={1}
                                                                    data={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                                />

                                                                <div className="k-form-buttons">
                                                                    <Button
                                                                        themeColor={"primary"}
                                                                        type={"submit"}
                                                                    >
                                                                        Apply Options
                                                                    </Button>
                                                                </div>
                                                                {statusMsg ?
                                                                    <div className='k-alert-error'>
                                                                        {statusMsg}
                                                                    </div> : ''}
                                                            </fieldset>
                                                        </FormElement>
                                                    )}
                                                />
                                            </Cell>
                                        </div>

                                    </TabStripTab>
                                    <TabStripTab title="Predictive without change" key="tab3">
                                        <div className="chart-tab-width">
                                            <div className="dashContainerHeader" key={'div'+Math.random()}><strong>Predictive models without
                                                change of consumption</strong></div>
                                            <Cell small={12} medium={12} large={12}>
                                                {PredictiveDataChart?.map((item, idx) => (
                                                    <div className="border-div" key={item.name+idx} >
                                                        <Cell small={12} medium={12} large={12}>
                                                            <h5>{item.name} -No change to consumption habits</h5>
                                                            <PredictiveChart
                                                                chartData={item.data}
                                                                categories={chartCategories}
                                                                title="KGCO2e by Source (Scope1 and Scope2)"
                                                                xaxisTitle="KgCO2e"
                                                                type="line"
                                                                name={base_year + 1 + "-" + (+base_year + years)}
                                                            />
                                                        </Cell>
                                                        <Cell small={12} medium={12} large={12}>
                                                            <DataGrid
                                                                data={orderBy(item.table, sort)}
                                                                sortable={true}
                                                                sort={sort}
                                                                onSortChange={(e) => {
                                                                    setSort(e.sort);
                                                                }}
                                                                key={item.name+Math.random()}
                                                            >
                                                                <Column field="year" title="Year"/>
                                                                <Column field="Jan" title="Jan" format="{0:n2}"/>
                                                                <Column field="Feb" title="Feb" format="{0:n2}"/>
                                                                <Column field="Mar" title="Mar" format="{0:n2}"/>
                                                                <Column field="Apr" title="Apr" format="{0:n2}"/>
                                                                <Column field="May" title="May" format="{0:n2}"/>
                                                                <Column field="Jun" title="Jun" format="{0:n2}"/>
                                                                <Column field="Jul" title="Jul" format="{0:n2}"/>
                                                                <Column field="Aug" title="Aug" format="{0:n2}"/>
                                                                <Column field="Sep" title="Sep" format="{0:n2}"/>
                                                                <Column field="Oct" title="Oct" format="{0:n2}"/>
                                                                <Column field="Nov" title="Nov" format="{0:n2}"/>
                                                                <Column field="Dec" title="Dec" format="{0:n2}"/>
                                                                <Column field="Total" title="Total" format="{0:n2}"/>
                                                            </DataGrid>
                                                        </Cell>
                                                    </div>
                                                ))}
                                            </Cell>
                                        </div>

                                    </TabStripTab>
                                    <TabStripTab title="Predictive with change" key="tab4">
                                        <div className="chart-tab-width" key="div-tab-4">
                                            <div className="dashContainerHeader" key="div-tab-41"><strong>Predictive models with change
                                                of consumption</strong></div>
                                            <Cell small={12} medium={12} large={12}>

                                                {PredictiveChangeDataChart?.map((item, idx) => (
                                                    <div className="padded_region" key={"div"+item.name+"change"}>

                                                        <Cell small={12} medium={12} large={12}>
                                                            <h5>{item.name} - Change to consumption habits</h5>
                                                            <form
                                                                id={item.name+"controls-form"}
                                                                key={"form"+item.name+"change"}>
                                                                <fieldset>
                                                                    {(item.name == 'Electricity') ? (
                                                                        <div>
                                                                            <legend>Solar PV</legend>
                                                                            <Checkbox
                                                                                id="solarpanelswitch"
                                                                                onClick={onChangeControl} label="Solar
                                                                            PV Panels included?"/>
                                                                            <input type="number"
                                                                                   id="solarpanelperc"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   placeholder="% of Solar Panel"
                                                                                   value={changeControlsForm['solarpanelperc']??''}
                                                                                   onChange={onChangeControl}
                                                                            />

                                                                            <legend>Green Energy</legend>

                                                                            <Checkbox
                                                                                id="greenenergyswitch" onClick={onChangeControl} label="Green
                                                                            Energy Supplier included?"/>
                                                                            <input type="number"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   id="greenenergyperc"
                                                                                   placeholder="% of Green energy"
                                                                                   value={changeControlsForm['greenenergyperc']??''}
                                                                                   onChange={onChangeControl}/>
                                                                            <legend>Wind Turbine</legend>

                                                                            <Checkbox
                                                                                id="windturbineswitch" onClick={onChangeControl} label="Wind
                                                                            Turbine included?"/>
                                                                            <input type="number"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   id="windturbineperc"
                                                                                   value={changeControlsForm['windturbineperc']??''}
                                                                                   placeholder="% of Energy from wind turbine" onChange={onChangeControl}/>

                                                                        </div>) : ''}

                                                                    {(item.name == 'Natural Gas') ? (
                                                                        <div>
                                                                            <legend>Biopropane</legend>
                                                                            <Checkbox
                                                                                id="biopropaneswitch" onClick={onChangeControl} label={"Biopropane as an alternative?"}/>
                                                                            <input type="number" id="biopropaneperc"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   placeholder="% of biopropane"
                                                                                   value={changeControlsForm['biopropaneperc']??''}
                                                                                   onChange={onChangeControl}/>
                                                                            <legend>Biomethane</legend>
                                                                            <Checkbox
                                                                                   id="biomethaneswitch" onClick={onChangeControl} label={"Biomethane as an alternative?"}/>
                                                                            <input type="number" id="biomethaneperc"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   placeholder="% of biomethane"
                                                                                   value={changeControlsForm['biomethaneperc']??''}
                                                                                   onChange={onChangeControl}
                                                                                    width = "300"/>
                                                                        </div>) : ''}
                                                                    {(item.name == 'Transport') ? (
                                                                        <div>
                                                                            <div>Hybrid Electric Vehicles</div>
                                                                            <Checkbox
                                                                                id="hybridswitch" onClick={onChangeControl} label="Hybrid
                                                                            Vehicles as an alternative?"/>
                                                                            <input type="number" id="hybridperc"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   placeholder="% of hybrid vehicles"
                                                                                   value={changeControlsForm['hybridperc']??''}
                                                                                   onChange={onChangeControl}/>
                                                                            <legend>Green Energy</legend>
                                                                            <div>Plugin Electric Vehicles</div>
                                                                            <Checkbox
                                                                                id="pluginswitch" onClick={onChangeControl}  label="Plugin
                                                                                Electric Vehicles as an alternative?"/>
                                                                            <input type="number" id="pluginperc"
                                                                                   min={"0"}
                                                                                   max={"100"}
                                                                                   placeholder="% of plugin vehicles"
                                                                                   value={changeControlsForm['pluginperc']??''}
                                                                                   onChange={onChangeControl}/>
                                                                        </div>) : ''}
                                                                    <input type="submit" id={item.name+"submit"}
                                                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                                                           value="Apply Options"
                                                                        onClick={(e) => handleChangeSubmit(e,item.name)} />
                                                                </fieldset>
                                                        </form>
                                                        </Cell>
                                                        <Cell small={12} medium={12} large={12}>

                                                            <PredictiveChart key={item.name+Math.random()}
                                                                chartData={item.data}
                                                                title="KGCO2e by Source (Scope1 and Scope2)"
                                                                xaxisTitle="KgCO2e"
                                                                type="line"
                                                                categories={chartCategories}
                                                                changeSeries={item.change_series??[]}
                                                                name={base_year + 1 + "-" + (+base_year + years)}
                                                            />
                                                        </Cell>
                                                        <Cell small={12} medium={12} large={12}>
                                                            <DataGrid
                                                                data={orderBy(item.change_table, sort)}
                                                                sortable={true}
                                                                sort={sort}
                                                                onSortChange={(e) => {
                                                                    setSort(e.sort);
                                                                }}
                                                                key={item.name+Math.random()}
                                                            >
                                                                <Column field="type" title="Type"/>
                                                                <Column field="year" title="Year"/>
                                                                <Column field="Jan" title="Jan" format="{0:n2}"/>
                                                                <Column field="Feb" title="Feb" format="{0:n2}"/>
                                                                <Column field="Mar" title="Mar" format="{0:n2}"/>
                                                                <Column field="Apr" title="Apr" format="{0:n2}"/>
                                                                <Column field="May" title="May" format="{0:n2}"/>
                                                                <Column field="Jun" title="Jun" format="{0:n2}"/>
                                                                <Column field="Jul" title="Jul" format="{0:n2}"/>
                                                                <Column field="Aug" title="Aug" format="{0:n2}"/>
                                                                <Column field="Sep" title="Sep" format="{0:n2}"/>
                                                                <Column field="Oct" title="Oct" format="{0:n2}"/>
                                                                <Column field="Nov" title="Nov" format="{0:n2}"/>
                                                                <Column field="Dec" title="Dec" format="{0:n2}"/>
                                                                <Column field="Total" title="Total" format="{0:n2}"/>
                                                            </DataGrid>
                                                        </Cell>
                                                    </div>
                                                ))}
                                            </Cell>
                                        </div>

                                    </TabStripTab>
                                    <TabStripTab title="Combined Report" key="tab5">
                                        <div className="chart-tab-width">
                                            <Cell small={12} medium={12} large={12}>
                                                <div className="dashContainerHeader"><strong>Combined reporting tables
                                                    for requested predictive values.</strong></div>
                                                <h4>Consumption without change values</h4>
                                                <DataGrid
                                                    data={PredictiveTotals}
                                                >
                                                    <Column field="type" title="Energy Type"/>
                                                    {yearArray?.map((year, idx) => (
                                                        <Column field={'total' + year} title={year + 'Total KgCO2e'} format="{0:n2}"/>
                                                    ))}

                                                    <Column field="total" title="Total KgCO2e" format="{0:n2}"/>

                                                </DataGrid>
                                            </Cell>
                                            <Cell small={12} medium={12} large={12}>
                                                <div className="dashContainerHeader"><strong>Combined reporting with Changes
                                                    for requested predictive values.</strong></div>
                                                <h4>Consumption with Changes values</h4>
                                                <DataGrid
                                                    data={PredictiveChangeTotals}
                                                >
                                                    <Column field="type" title="Energy Type"/>
                                                    {yearArray?.map((year, idx) => (
                                                        <Column field={'total' + year} title={year + 'Total KgCO2e'} format="{0:n2}"/>
                                                    ))}

                                                    <Column field="total" title="Total KgCO2e" format="{0:n2}"/>

                                                </DataGrid>
                                            </Cell>
                                        </div>

                                    </TabStripTab>
                                </TabStrip>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default PredectiveModel;