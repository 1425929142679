import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React, {useState, useEffect} from "react";
import axiosApi from "../../api/axiosInstance";
// import * as React from "@types/react";

function AddWaterEntry() {
    const { site } = useParams();

    const value = new Date();
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const handleDayChange = (e) => {
        setDay(e.target.value);
    };

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    const [meterID, setMeterID] = useState("");
    const [meterReading, setMeterReading] = useState("");
    const [amount, setAmount] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [metersList, setMetersList] = useState([]);
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');

    // Construct the date_added string
    const dateAdded =year + '-' + paddedMonth + '-' + paddedDay;

    const requestData = {
        site_id: site,
        date_added: dateAdded,
        meter_id: meterID,
    };

    if (meterReading !== "") {
        requestData.meter_reading = meterReading;
    }

    if (amount !== "") {
        requestData.amount = amount;
    }

    const handleSave = (event) => {
        event.preventDefault(); 
        axiosApi.post('/pillar/2/site-water-meter-entries?site_id=' + site, requestData)
        .then((response) => {
            console.log(response.data);
            // Clear form inputs
            setMeterID("");
            setMeterReading("");
            setAmount("");
            setDay("");
            setMonth("");
            setYear("");
            // Set success message
            setSuccessMessage("Water meter entry added successfully!");
        })
        .catch((error) => {
            console.error(error);
            // Handle error if needed
        });
    };

    useEffect(() => {
        const loadMeters = async () => {
            axiosApi.get(
                '/pillar/2/site-water-meters',
                {
                    params: {
                        site_id: site
                    }
                }
            ).then(function (response) {
                console.log(response.data);
                if (response['data']['data']['list'].length > 0) {
                    // Extract meter_names from the list of meters
                    const meterList = response['data']['data']['list'];
                    setMetersList(meterList);
                }
            });
        }
        loadMeters();
    }, [site]);

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="waterNavigation">
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/water-meters/'+site} className="nav-link">Water Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/water-meter-entries/'+site} className="nav-link">Water Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/water/'+site} className="nav-link">Water Meter Entry Upload</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/water-meter-entries/' + site}>&#11164; Back to Water Meter Entries</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Add Water Entry</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <div className="quickSelectTab">
                                    <h4>Water Entry</h4>
                                    <form onSubmit={handleSave}>
                                        <div>
                                            <p>Enter Date</p>
                                        </div>
                                        <div>
                                            <select name="date_start_day" id="date_start_day" style={{ width: '33%' }} value={day} onChange={handleDayChange}>
                                                <option value="">DD</option>
                                                {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                                                    <option key={day} value={day}>{day}</option>
                                                ))}
                                            </select>

                                            <select name="date_start_month" id="date_start_month" style={{ width: '33%' }} className="c-select c-select--custom c-select__date u-width-full" value={month} onChange={handleMonthChange}>
                                                <option value="">MM</option>
                                                {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => {
                                                    const monthObj = new Date(0, month - 1);
                                                    const monthName = monthObj.toLocaleString('default', { month: 'short' });
                                                    return <option key={month} value={month}>{monthName}</option>;
                                                })}
                                            </select>

                                            <select name="date_start_year" id="date_start_year" style={{ width: '33%' }} className="c-select c-select--custom c-select__date u-width-full" value={year} onChange={handleYearChange}>
                                                <option value="">YYYY</option>
                                                {Array.from({ length: 24 }, (_, index) => new Date().getFullYear() - 20 + index).map((year) => (
                                                    <option key={year} value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <p>Meter</p>
                                        </div>
                                        <select name="meter_id" id="meter_id" className="c-select c-select--custom u-width-full"  value={meterID} onChange={(e) => setMeterID(e.target.value)}>
                                            <option value="">- select -</option>
                                            {metersList.map((meter, index) => (
                                                <option key={index} value={meter.id}>{meter.meter_name}</option>
                                            ))}
                                        </select>
                                        <hr/>
                                            <input type="number" id="meterReading" name="meterReading" value={meterReading} placeholder="Meter Reading" onChange={(e) => setMeterReading(e.target.value)}/>
                                        <div>
                                            <p>Or</p>
                                        </div>
                                            <input type="number" id="amount" name="amount" value={amount} placeholder="Amount" onChange={(e) => setAmount(e.target.value)}/>
                                        <hr/>
                                        <button type='submit' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Save</button>
                                        {successMessage && <p>{successMessage}</p>}
                                    </form>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default AddWaterEntry;