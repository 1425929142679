import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";

import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import BiodiversityNavbar from "./Navbar";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { FormUpload, FormInput,FormNumericTextBox,FormRadioGroup } from  "../form/form-components"
import {stringValidator, requiredValidator, emailValidator} from "../form/validators";

function BiodiversityStatus() {
    const [BioId, setBioId] = useState('');
    const [BioData, setBioData] = useState({});
    const [siteName, setSiteName] = useState('');
    const [loading, setLoading] = useState(true);
    const[statusMsg, setStatusMsg] = useState('');

    const params = useParams();
    const site = params['site'];

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            }).catch(function(error) {
                window.location.replace("/notfound")
            });
        }
        loadSite();

        const loadBiodiversity = async () => {

            axiosApi.get(
                '/pillar/2/biodiversity',
                {
                    params: {
                        site_id: site
                    }
                }
            ).then(function (response) {
                let data = response['data']['data'];
                if (data['id']) {
                    let form_data = {
                        eco_survey: data['eco_survey'],
                        recommendations_required: data['recommendations_required'],
                        recommendations: data['recommendations'],
                        implemented_recommendations: data['implemented_recommendations'],
                        net_biodiversity_gain: data['net_biodiversity_gain'],
                        net_biodiversity_gain_percentage: data['net_biodiversity_gain_percentage'],
                        bio_champion: data['bio_champion'],
                        person_name: data['person_name'],
                        person_email: data['person_email'],
                    };
                    setBioId(data['id']);
                    setBioData(Biodata => ({
                        ...Biodata,
                        ...form_data
                    }));

                }
                setLoading(false);
            });
        }
        loadBiodiversity();

    },[site]);



    const handleSubmit = (dataItem) => {
        //alert(JSON.stringify(dataItem, null, 2));
        let formData =  dataItem;
        formData['site_id'] = site

        let isUpdate = !!BioId;
        if(isUpdate) {
            formData['_method'] = "put"
        }

        axiosApi.postForm('pillar/2/biodiversity'+ (BioId ? '/'+BioId : ''), formData)
            .then(function (response) {
                let data = response['data']['data'];
                let form_data = {
                    eco_survey: data['eco_survey'],
                    recommendations_required: data['recommendations_required'],
                    recommendations: data['recommendations'],
                    implemented_recommendations: data['implemented_recommendations'],
                    net_biodiversity_gain: data['net_biodiversity_gain'],
                    net_biodiversity_gain_percentage: data['net_biodiversity_gain_percentage'],
                    bio_champion: data['bio_champion'],
                    person_name: data['person_name'],
                    person_email: data['person_email'],
                };
                setStatusMsg('Updated Successfuly');
                setBioData(Biodata => ({
                    ...Biodata,
                    ...form_data
                }));
            }).catch(function (error) {
            //handle error
            console.log(error);
        });
        window.scrollTo(0, 0);
    };
    const confirmationData = [
        {
            label: "Yes",
            value: 1,
        },
        {
            label: "No",
            value: 2,
        },
    ];
    const recommendationData  = [
        {
            label: "Completed",
            value: 1,
        },
        {
            label: "InProgress",
            value: 2,
        },
        {
            label: "No",
            value: 3,
        },
    ];
    if(!loading) {

    return (
        <Grid>
            <BiodiversityNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={12}>
                            <div className="padRegion">
                                <Link to={'/biodiversity/dashboard/' + site} className="linkBack">&#11164; Back to Biodiversity Dashboard</Link>
                            </div>
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Biodiversity Status -{ siteName }</strong></div>
                        <Grid className="display">

                            <Cell small={12} medium={12} large={6}>
                                {statusMsg ?
                                <div className='notesHeading'>
                                    {statusMsg}
                                </div> : '' }
                                <Form
                                    initialValues={BioData}
                                    onSubmit={handleSubmit}
                                    render={(formRenderProps) => (
                                        <FormElement
                                            style={{
                                                width: 400,
                                            }}
                                        >
                                            <fieldset className={"k-form-fieldset"}>
                                                <legend className={"k-form-legend"}>
                                                    Biodiversity
                                                </legend>
                                                <Field
                                                    id={"eco_survey"}
                                                    name={"eco_survey"}
                                                    label={"Has a biodiversity survey of your school site been completed?"}
                                                    component={FormRadioGroup}
                                                    data={confirmationData}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    id={"recommendations_required"}
                                                    name={"recommendations_required"}
                                                    label={"Are there any recommendations from the survey?"}
                                                    component={FormRadioGroup}
                                                    data={confirmationData}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    id={"recommendations"}
                                                    name={"recommendations"}
                                                    label={"Recommendations"}
                                                    component={FormInput}
                                                />
                                                <Field
                                                    id={"implemented_recommendations"}
                                                    name={"implemented_recommendations"}
                                                    label={"Have the recommendation of the survey been implemented?"}
                                                    component={FormRadioGroup}
                                                    data={recommendationData}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    id={"net_biodiversity_gain"}
                                                    name={"net_biodiversity_gain"}
                                                    label={"Do you expect biodiversity improvement on this project?"}
                                                    component={FormRadioGroup}
                                                    data={confirmationData}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    id={"net_biodiversity_gain_percentage"}
                                                    name={"net_biodiversity_gain_percentage"}
                                                    label={"Biodiversity improvement Percentage"}
                                                    hint={"Hint: % of improvement."}
                                                    format={"n2"}
                                                    component={FormNumericTextBox}
                                                />
                                                <Field
                                                    id={"bio_champion"}
                                                    name={"bio_champion"}
                                                    label={"Have you nominated a biodiversity champion?"}
                                                    component={FormRadioGroup}
                                                    data={confirmationData}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    id={"person_name"}
                                                    name={"person_name"}
                                                    label={"Biodiversity Champion Name"}
                                                    component={FormInput}
                                                />

                                                <Field
                                                    id={"person_email"}
                                                    name={"person_email"}
                                                    label={"Biodiversity Champion Email"}
                                                    component={FormInput}
                                                    validator={emailValidator}
                                                />

                                                <div className="k-form-buttons">
                                                    <Button
                                                        themeColor={"primary"}
                                                        type={"submit"}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </FormElement>
                                    )}
                                />
                            </Cell>

                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
    }
    return null;
}

export default BiodiversityStatus;