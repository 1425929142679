import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { orderBy } from "@progress/kendo-data-query";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {FormatEntries} from "./TransportHelper";
import EnergyChart from "../Energy/EnergyChart";
import {parseNumber} from "../form/format-helper";
import {formatNumber} from "@progress/kendo-intl";
function TransportLanding() {
    const [TransportDataChart, setTransportDataChart] = useState([]);

    const [TransportDataTable, setTransportDataTable] = useState([]);
    const [TransportVehicleChart, setTransportVehicleChart] = useState([]);

    const[TransportSiteTotal, setTransportSiteTotal] = useState([]);

    const [siteName, setSiteName] = useState('');
    const [vehicles, setVehicles] = useState([]);

    const params = useParams();
    const site = params['site'];


    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };



    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadTransportGraph = async () => {
            let site_id = site;
            let url = '/pillar/2/site-transports/report-summary/site';
            let params = {
                site_id: site_id,
                start_date: startDate,
                end_date: endDate
            };

            //get energy data
            axiosApi.get(
                url,
                {
                    params: params
                }
            ).then(function (response) {
                let data = response['data'];
                let entries = [];
                let siteTotal =[];

                entries = data['data']['list'] ?? [];

                setTransportDataTable(FormatEntries(entries,false));

                //chart by Day
                entries = data['data']['summary']['by_day'] ?? [];
                setTransportDataChart(FormatEntries(entries,true));

                //get Site Total
                siteTotal['miles'] = parseNumber(data['data']['summary']['total_miles']??0);
                entries = data['data']['summary']['overall'];
                for (let j = 0; j < entries.length; j++) {
                    let ghg_emission = entries[j];
                    if (ghg_emission['measurement'] === 'kgCO2e') {
                        siteTotal['kgco2e'] = parseNumber(ghg_emission['total_amount']);
                    }
                }
                setTransportSiteTotal(siteTotal);

                //Chart by Vehicle
                entries = data['data']['summary']['vehicle_summary'] ?? [];
                let vehicles = [];
                let kgco2Data = [];
                let kgco2eData =[];
                let milesData =[];
                let vehicleData = [];
                for(let vehicle in entries) {
                    vehicles.push(vehicle);

                    let entry = entries[vehicle];
                    milesData.push({
                        value : parseNumber(entry['miles']),
                        category: vehicle
                    });

                    let ghg_emissions = entry['ghg_emissions'];
                    for (let j = 0; j < ghg_emissions.length; j++) {
                        if (ghg_emissions[j]['measurement'] ==='kgCO2e') {
                            kgco2eData.push({
                                value : parseNumber(ghg_emissions[j]['amount']),
                                category: vehicle
                            });
                        }
                    }
                }

                setVehicles(vehicles);

                vehicleData.push(
                    {
                        name: "miles",
                        data: milesData
                    });
                vehicleData.push({
                        name: "KgCO2e",
                        data: kgco2eData
                    });

                setTransportVehicleChart(vehicleData);
            });
            //get data for the table

        }
        loadTransportGraph();

    },[site,startDate,endDate]);


    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Transport Reporting</h5>
                    <hr/>
                    <ul>
                        <li>
                            <Link to={'/workshopLanding/' + site} className="nav-link">Transport Report</Link>
                        </li>
                        <li>
                            <Link to={'/transport-workshop/list/' + site} className="nav-link">Transport List</Link>
                        </li>
                    </ul>
                    <hr/>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/siteLanding/' + site} className="linkBack">&#11164; Back to Site Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Transport Summary -{ siteName }</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <div className="dashContainerHeader">
                                    {TransportSiteTotal['miles'] ?
                                    <table className="statsTable">
                                        <tr>
                                            <td>Total Miles: {formatNumber(TransportSiteTotal['miles'] , 'n2')}</td>
                                            <td>Total KgCO2e: {formatNumber(TransportSiteTotal['kgco2e'], 'n2')}</td>
                                        </tr>
                                    </table> : '' }
                                </div>
                                <EnergyChart
                                    chartData={TransportDataChart}
                                    title="Site Transport Miles vs KgCO2e by Day"
                                    xaxisTitle = " "
                                    yaxisTitle = "Day"
                                    type = "line"

                                />
                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                                <div className="quickSelectTab">
                                    <h6>Transport Summary By Vehicles</h6>
                                    <table className="statsTable">
                                        <td>
                                            <tr><td>Vehicle:</td></tr>
                                            <tr><td>Total Miles:</td></tr>
                                            <tr><td>Total KgCO2e:</td></tr>
                                        </td>
                                        {vehicles?.map((item,idx) => (
                                                <td>
                                                <tr>
                                                    <td>{item}</td>
                                                </tr>
                                                {TransportVehicleChart?.map((item) => (
                                                    <tr>
                                                        <td>{formatNumber(item.data[idx].value , 'n2')}</td>
                                                    </tr>
                                                )) }
                                                </td>
                                        ))}
                                    </table>

                                </div>
                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                            <EnergyChart
                                chartData={TransportVehicleChart}
                                title="Total Miles, KgCO22, kgCO2e by vehicle"
                                xaxisTitle = " "
                                yaxisTitle = "Vehicle"
                                angle = "0"
                                type = "column"
                            />

                            </Cell>
                            <Cell small={12} medium={12} large={12}>
                                <ExcelExport data={TransportDataTable}>
                                    <DataGrid
                                        style={{
                                            height: "600px",
                                        }}
                                        data={orderBy(TransportDataTable, sort)}
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={(e) => {
                                            setSort(e.sort);
                                        }}
                                    >
                                        <Toolbar>
                                            <Link to={'/transport/list/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                               See All Inputs
                                            </Link>
                                        </Toolbar>
                                        <Column field="Registration" title="Registration" />
                                        <Column field="Make" title="Make" />
                                        <Column field="Model" title="Model" />
                                        <Column field="Type" title="Type" />
                                        <Column field="Miles" title="Miles" format="{0:n2}" />
                                        <Column field="CO2eTotal" title="CO2eTotal" format="{0:n2}" />
                                        <Column field="tCO2eTotal" title="tCO2eTotal" format="{0:n4}" />
                                        <Column field="From" title="From" />
                                        <Column field="To" title="to" />
                                        <Column field="Date" title="Date" />
                                        <Column field="FromTime" title="FromTime" />
                                        <Column field="ToTime" title="ToTime" />
                                        <Column field="Source" title="Source" />
                                    </DataGrid>
                                </ExcelExport>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default TransportLanding;