export const electricConversions = {
    '2023': 0.207074,
    '2024': 0.210180,
    '2025': 0.213332,
    '2026': 0.216531,
    '2027': 0.219778,
    '2028': 0.223074,
    '2029': 0.226420,
    '2030': 0.229816,
    '2031': 0.233263,
    '2032': 0.236761,
    '2033': 0.240312
};
export const gasConversions = {
    '2023': 2.04,
    '2024': 2.0706,
    '2025': 2.101659,
    '2026': 2.133183,
    '2027': 2.165180,
    '2028': 2.197657,
    '2029': 2.230621,
    '2030': 2.264080,
    '2031': 2.298041,
    '2032': 2.332511,
    '2033': 2.367498
};
export const dieselConversions = {
    '2023': 2.66,
    '2024': 2.6999,
    '2025': 2.740398,
    '2026': 2.781503,
    '2027': 2.823225,
    '2028': 2.865573,
    '2029': 2.908556,
    '2030': 2.952184,
    '2031': 2.996466,
    '2032': 3.041412,
    '2033': 3.087033
};
export const lpgConversions = {
    '2023': 1.56,
    '2024': 1.5834,
    '2025': 1.607151,
    '2026': 1.631258,
    '2027': 1.655726,
    '2028': 1.680561,
    '2029': 1.705769,
    '2030': 1.731355,
    '2031': 1.757325,
    '2032': 1.783684,
    '2033': 1.810439
};
export const fueloilConversions = {
    '2023': 3.17,
    '2024': 3.21755,
    '2025': 3.265813,
    '2026': 3.314800,
    '2027': 3.364522,
    '2028': 3.414989,
    '2029': 3.466213,
    '2030': 3.518206,
    '2031': 3.570979,
    '2032': 3.624543,
    '2033': 3.678911
};
export const petrolConversions = {
    '2023': 2.35,
    '2024': 2.38525,
    '2025': 2.421028,
    '2026': 2.457343,
    '2027': 2.494203,
    '2028': 2.531616,
    '2029': 2.569590,
    '2030': 2.608133,
    '2031': 2.647254,
    '2032': 2.686962,
    '2033': 2.727266,
    '2034': 2.747266
};
export const burningConversions = {
    '2023': 2.54,
    '2024': 2.5781,
    '2025': 2.616771,
    '2026': 2.656022,
    '2027': 2.695862,
    '2028': 2.736299,
    '2029': 2.777343,
    '2030': 2.819003,
    '2031': 2.861288,
    '2032': 2.904207,
    '2033': 2.947770,
    '2034': 2.987770
};
export const gasoilConversions = {
    '2023': 2.76,
    '2024': 2.8014,
    '2025': 2.843421,
    '2026': 2.886072,
    '2027': 2.929363,
    '2028': 2.973303,
    '2029': 3.017902,
    '2030': 3.063170,
    '2031': 3.109117,
    '2032': 3.155753,
    '2033': 3.203089,
    '2034': 3.243089
};
export const transportConversions = {
    '2023': 0.26379,
    '2024': 0.267746,
    '2025': 0.271762,
    '2026': 0.275838,
    '2027': 0.279975,
    '2028': 0.284174,
    '2029': 0.288436,
    '2030': 0.292762,
    '2031': 0.297153,
    '2032': 0.301610,
    '2033': 0.306134,
    '2034': 0.346134
};
