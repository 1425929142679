import {parseNumber} from "../form/format-helper";
export  function FormatEntries(entries, chart=true)
{

    const site_transport_series = [];
    const category = [];
    const kgco2eData =[];
    const milesData =[];
    let meterEntries = [];

    if (entries.length > 0) {
        for (let i = 0; i < entries.length; i++) {
            let entry = entries[i];
            let kgco2e = 0;
            let tco2e = 0;
            let ghg_emissions = entry['ghg_emissions'];

            for (let j = 0; j < ghg_emissions.length; j++) {
                let ghg_emission = ghg_emissions[j];
                if (ghg_emission['measurement'] === 'kgCO2e') {
                    kgco2e = ghg_emission['amount'];
                    tco2e = ghg_emission['amount'] / 1000;
                }
            }
            if(chart) {
                kgco2eData.push({
                    value: parseNumber(kgco2e),
                    category: entry['date']??entry['month']
                });
                milesData.push({
                    value: parseNumber(entry['miles'] ?? entry['kilometers'] * 0.621371  ),
                    category: entry['date']??entry['month']
                });

            } else {
                meterEntries.push({
                    "Registration" : entry['vehicle']['registration'],
                    "Make": entry['vehicle']['vehicle_details']['make'],
                    "Model": entry['vehicle']['vehicle_details']['model'],
                    "Miles": parseNumber(entry['miles']),
                    "Date": entry['added_on'],
                    "From": entry['location_from'],
                    "To": entry['location_to'],
                    "FromTime": entry['from_time'],
                    "ToTime": entry['to_time'],
                    "Source": 'api',
                    "CO2eTotal": parseNumber(kgco2e),
                    "tCO2eTotal": parseNumber(tco2e,4),
                    "Type": entry['vehicle']['vehicle_details']['type'],
                    "EntryType": entry['entry_type']
                });
            }

        }
    }
    if(chart) {
        site_transport_series.push({
            name: 'KgCO2e',
            data: kgco2eData
        });
        site_transport_series.push({
            name: 'Miles',
            data: milesData
        });
    }
    return chart ? site_transport_series : meterEntries;
}
