import {Link, useParams} from "react-router-dom";
import {Cell} from "react-foundation";
import * as React from "react";
const EnergyNavbar = () => {
    const params = useParams();
    const siteid = params['site'];
    const id  = params['id'] ?? '';

    return (
        <Cell small={12} medium={2} large={2}>
            <div className="energyNavigation">
                <h5>Scope 1 Emission Sources</h5>
                <ul>
                    <li>
                        <Link to={'/energy/' + siteid + '/2'}  onClick={() => { window.location.href = '/energy/' + siteid + '/2'; }} className="nav-link">Natural Gas</Link>
                    </li>
                    <li>
                        <Link to={'/energy/' + siteid + '/6'} className="nav-link" onClick={() => { window.location.href = '/energy/' + siteid + '/6'; }}>LPG</Link>
                    </li>
                    <li>
                        <Link to={'/energy/' + siteid + '/16'} className="nav-link" onClick={() => { window.location.href = '/energy/' + siteid + '/16'; }}>Gas Oil</Link>
                    </li>
                    <li>
                        <Link to={'/energy/' + siteid + '/15'} className="nav-link" onClick={() => { window.location.href = '/energy/' + siteid + '/15'; }}>Burning Oil</Link>
                    </li>
                </ul>
                <hr/>
                <h5>Scope 2 Emission Sources</h5>
                <ul>
                    <li>
                        <Link to={'/energy/' + siteid + '/1'} className="nav-link"  onClick={() => { window.location.href = '/energy/' + siteid + '/1'; }} >Electric</Link>
                    </li>
                </ul>
                <hr/>
                {/*<h5>Usage Type Sub-Sections</h5>*/}
                {/*<ul className="submenuNav">*/}
                {/*    <li>*/}
                {/*        <Link to={'/meter/'+site+'/1'} className="nav-link">IT</Link>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*        <Link to={'/meter/'+site+'/2'} className="nav-link">Kitchen</Link>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*<hr/>*/}
                <h5>Inputs</h5>
                <ul>
                    <li>
                        <Link to={'/energy-meters/' + siteid} className="nav-link">Energy Meters</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to={'/input/energy/' + siteid} className="nav-link">Energy Meter Entry Upload</Link>
                    </li>
                </ul>
                <hr/>

            </div>
        </Cell>
    )
}

export default EnergyNavbar