import React from 'react'
import {Grid, Cell} from "react-foundation";

import {Link, NavLink, useNavigate, useLocation} from "react-router-dom"

const Navbar = () => {
    const location = useLocation();

    return (
        <nav className="navbar_top">
            <Grid className="display">
                <Cell small={12} medium={4} large={4}>
                    <div className="companyLogo">
                        <Link to='/'><img src="/images/Logo_White-01.png" alt="SustainIQ Company Logo" /></Link>
                    </div>
                </Cell>
                <Cell small={12} medium={8} large={8}>
                    {location.pathname === '/login' || location.pathname === '/logout' ? (
                        <ul className="navbar_top_list">
                            <li><Link to={'/login'} className="nav-link">Login</Link></li>
                        </ul>
                    ) : (
                        < ul className = "navbar_top_list" >
                            < li > < Link to={'/'} className="nav-link">Home</Link></li>
                            <li><Link to={'/sites'} className="nav-link">Sites</Link></li>
                            <li><Link to={'/company-targets'} className="nav-link">Company Targets</Link></li>
                            {/*<li><Link to={'/asset/import'} className="nav-link">Assets</Link></li>*/}
                            <li><Link to={'/workshops'} className="nav-link">Transport-Sites</Link></li>
                            <li><Link to={'/predictive-model'} className="nav-link">Predictive</Link></li>
                            <li><Link to={'/help'} className="nav-link">Help</Link></li>
                            <li><Link to={'/logout'} className="nav-link">Logout</Link></li>
                        </ul>
                    )}
                </Cell>
            </Grid>
        </nav>
    )
}
export default Navbar