import * as React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    Sparkline,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartTitle,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem,
} from '@progress/kendo-react-charts';
export default function WasteUsageChart(props) {

    const categoryAxisMax = new Date(2022, 1, 0);
    const categoryAxisMaxDivisions = 10;

    return (
        <Chart
            pannable={{
                lock: "y",
            }}
            zoomable={{
                mousewheel: {
                    lock: "y",
                },
                selection: {
                    lock: "y",
                },
            }}
        >
            <ChartTitle text={props.title} />
            <ChartLegend position="bottom" />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    max={categoryAxisMax}
                    maxDivisions={categoryAxisMaxDivisions}
                    labels={{
                        font: "10px Arial,Helvetica,sans-serif",
                        rotation: {
                            angle: props.angle ?? 270
                        }
                    }}
                >
                    <ChartCategoryAxisTitle text= { props.categoryAxisTitle ?? '' } />
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem
                    title={{
                        text: props.xaxisTitle??'',
                        position: "top"
                    }}
                    labels={{
                        visible: true,
                    }}
                    name="Water Usage Amount"
                />
            </ChartValueAxis>
            <ChartSeries>
                {props.chartData?.map((item, idx) => (
                    <ChartSeriesItem
                        key={idx}
                        color={props.color??''}
                        type={props.type}
                        tooltip={{ visible: true }}
                        data={item.data}
                        name={item.name ?? props.yaxisTitle??''}
                        field="value" categoryField="category"
                    />
                ))}
            </ChartSeries>
        </Chart>

    );
}