import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";

import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import BiodiversityNavbar from "./Navbar";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
    FormUpload,
    FormInput,
    FormTextArea,
    FormRadioGroup,
    FormDropDownList,
    FormDateInput, FormDatePicker
} from "../form/form-components"
import {nameValidator, requiredValidator} from "../form/validators";

function AddInitiative() {
    const [siteName, setSiteName] = useState('');
    const [statusMsg, setStatusMsg] = useState('');
    const[FormData, setFormData] = useState({})

    const params = useParams();
    const site = params['site'];

    const types = ["Initiative",
        "Ecology Protection Measure",
        "Sightings",
        "Tree Planting"];

    const dataQuality  = ["Approximate","Estimated","Exact"];

    const types_values = {
        'Initiative' : 1,
        'Ecology Protection Measure' : 2,
        Sightings : 3,
        'Tree Planting' : 4
    }
    const dataQuality_values = {
        'Approximate' : 1,
        'Estimated' : 2,
        'Exact' : 3,
    }

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            }).catch(function(error) {
                window.location.replace("/notfound")
            });
        }
        loadSite();

    },[site]);



    const handleSubmit = (dataItem,e) => {
        //alert(JSON.stringify(dataItem, null, 2));
        console.log(dataItem['start_date']);
        let startDate= new Date(dataItem['start_date']);
        let data = {
            site_id: site,
            title: dataItem['title'],
            date : startDate.getFullYear() +'-'+startDate.getMonth() +'-'+startDate.getDate(),
            description: dataItem['description'],
            location: dataItem['location'],
            quantity: dataItem['quantity'],
            data_quality_measure_id: dataQuality_values[dataItem['data_quality']]
        }

        axiosApi.postForm('pillar/2/biodiversity-initiatives', data)
            .then(function (response) {
                //let data = response['data']['data'];
                alert('Initiative Created Successfully');

            }).catch(function (error) {
            //handle error
            console.log(error);
        });
        window.scrollTo(0, 0);

    };


        return (
            <Grid>
                <BiodiversityNavbar/>
                <Cell small={12} medium={10} large={10}>
                    <div className="selectionBar">
                        <Grid>
                            <Cell small={12} medium={12} large={12}>
                                <div className="padRegion">
                                    <Link to={'/biodiversity/dashboard/' + site} className="linkBack">&#11164; Back to Biodiversity Dashboard</Link>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                    <div className="dashboard">
                        <div className="dashContainer">
                            <div className="dashContainerHeader"><strong>Biodiversity Add Initiative -{ siteName }</strong></div>
                            <Grid className="display">
                                <Cell small={12} medium={12} large={6}>
                                    <Form
                                        name="add-form"
                                            onSubmit={handleSubmit}
                                        render={(formRenderProps) => (
                                            <FormElement
                                                style={{
                                                    width: 400,
                                                }}
                                            >
                                                <fieldset className={"k-form-fieldset"}>
                                                    {/*<legend className={"k-form-legend"}>
                                                        Add Biodiversity Initiative
                                                    </legend>
                                                    <Field
                                                        id={"biodiversity_type"}
                                                        name={"biodiversity_type"}
                                                        label={"Select Biodiversity Entry Type"}
                                                        component={FormDropDownList}
                                                        data={types}
                                                        validator={requiredValidator}
                                                    /> */}
                                                    <Field
                                                        id={"title"}
                                                        name={"title"}
                                                        label={"Biodiversity Initiative Title"}
                                                        component={FormInput}
                                                        validator={requiredValidator}
                                                    />
                                                    <Field
                                                        id={"start_date"}
                                                        name={"start_date"}
                                                        label={"Biodiversity Initiative Start Date"}
                                                        component={FormDatePicker}
                                                        format={"dd-MM-yyyy"}
                                                        defaultShow={false}
                                                        max={new Date()}
                                                        validator={requiredValidator}
                                                    />
                                                    <Field
                                                        id={"description"}
                                                        name={"description"}
                                                        label={"Biodiversity Initiative Description"}
                                                        component={FormTextArea}
                                                        validator={requiredValidator}
                                                        hint={"Note: please include species planted, size of area planted or number of trees planted, length of hedgerow planted"}
                                                    />
                                                    <Field
                                                        id={"location"}
                                                        name={"location"}
                                                        label={"Project Site location"}
                                                        component={FormInput}
                                                        hint = "Area of school grounds the work is happening on"
                                                    />
                                                    {/*
                                                    <Field
                                                        id={"quantity"}
                                                        name={"quantity"}
                                                        label={"No of Trees"}
                                                        format={"n"}
                                                        min={0}
                                                        component={FormNumericTextBox}
                                                    />*/}
                                                    <Field
                                                        id={"data_quality"}
                                                        name={"data_quality"}
                                                        label={"Type of data entered"}
                                                        component={FormDropDownList}
                                                        data={dataQuality}
                                                        validator={requiredValidator}
                                                    />


                                                    <div className="k-form-buttons">
                                                        <Button
                                                            themeColor={"primary"}
                                                            type={"submit"}
                                                        >
                                                            Submit
                                                        </Button>

                                                    </div>
                                                </fieldset>
                                            </FormElement>
                                        )}
                                    />
                                </Cell>

                            </Grid>
                        </div>
                    </div>
                </Cell>
            </Grid>
        );
}

export default AddInitiative;