import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    // sessionStorage.removeItem('auth-token');
    // const logout = () => {
        localStorage.clear();
        navigate('/login');
    // }

    return (
        <div className="login-page">
            <h2>You have been Logged out.</h2>
            <p>Your current session has ended, please return to <Link to='/login'>Login</Link> if you want to access the system.</p>
        </div>
    )
}

export default Logout;