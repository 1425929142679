import React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
} from "@progress/kendo-react-charts";

export default function DailyTimelineChart(props) {
  // Extracting data from props
  const { title, chartData } = props;

  // Function to convert timestamp to date string
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  // Function to generate data points for each day
  const generateDailyData = () => {
    const dailyData = {};

    // Iterate over chartData to populate dailyData
    chartData.forEach((item) => {
      item.data.forEach((dataPoint) => {
        const date = formatDate(dataPoint.category);

        if (!dailyData[date]) {
          dailyData[date] = [];
        }

        dailyData[date].push({
          name: item.name,
          value: dataPoint.value,
        });
      });
    });

    // Convert dailyData object into an array of objects
    const dailyChartData = Object.keys(dailyData).map((date) => ({
      category: date,
      data: dailyData[date],
    }));

    return dailyChartData;
  };

  // Generate data for daily timeline chart
  const dailyChartData = generateDailyData();

  return (
    <Chart>
      <ChartTitle text={title} />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={dailyChartData.map((item) => item.category)}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem title={{ text: "Amount" }} />
      </ChartValueAxis>
      <ChartSeries>
        {dailyChartData.map((item, index) => (
          <ChartSeriesItem
            key={index}
            type="line"
            data={item.data}
            name={item.category}
            field="value"
            categoryField="name"
          />
        ))}
      </ChartSeries>
    </Chart>
  );
}
