import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {filterBy, orderBy} from "@progress/kendo-data-query";
import {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {FormatEntries} from "./TransportHelper";
import EnergyChart from "../Energy/EnergyChart";
import {parseNumber} from "../form/format-helper";

function TransportWorkshopLanding() {
    const [TransportTotalChart, setTransportTotalChart] = useState([]);
    const [TransportDataTable, setTransportDataTable] = useState([]);

    const [TransportVehicleData, setTransportVehicleData] = useState([]);
    const [TransportVehicleMonthChart, setTransportVehicleMonthChart] = useState([]);

    const[TransportSiteTotal, setTransportSiteTotal] = useState([]);

    const [siteName, setSiteName] = useState('');

    const params = useParams();
    const site = params['site'];

    const [filter, setFilter] = React.useState();

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);
    const sortChange = (event) => {
        setTransportVehicleData(getList(event.sort));
        setSort(event.sort);
    };
    const getList = (sort) => {
        return orderBy(TransportVehicleData, sort);
    };

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };

    //handle Tabstrip
    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };



    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/pillar/2/transport-workshops/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadTransportWorkshopGraph = async () => {
            let site_id = site;
            let url = '/pillar/2/site-transports/report-summary/workshop';
            let params = {
                workshop_id: site_id,
                start_date: startDate,
                end_date: endDate
            };

            //get  data
            axiosApi.get(
                url,
                {
                    params: params
                }
            ).then(function (response) {
                let data = response['data'];
                let entries = [];
                let siteTotal =[];

                entries = data['data']['list'] ?? [];

                setTransportDataTable(FormatEntries(entries,false));

                //chart by Day
                entries = data['data']['summary']['by_month'] ?? [];
                setTransportTotalChart(FormatEntries(entries,true));


                //get Site Total
                siteTotal['miles'] = parseNumber(data['data']['summary']['total_miles']??0);
                entries = data['data']['summary']['overall'];
                for (let j = 0; j < entries.length; j++) {
                    let ghg_emission = entries[j];
                    if (ghg_emission['measurement'] === 'kgCO2e') {
                        siteTotal['kgco2e'] = parseNumber(ghg_emission['total_amount']);
                    }
                }
                setTransportSiteTotal(siteTotal);


                //Chart by Vehicle Totals
                entries = data['data']['summary']['by_vehicle'] ?? [];

                if (entries.length > 0) {
                    let chart_data = [];
                    for (let i = 0; i < entries.length; i++) {
                        let entry = entries[i];
                        let vehicleName = entry['registartion'];
                        let monthData = [];
                        for (let j = 0; j < entry['by_month'].length; j++) {
                            let row = entry['by_month'][j];
                            monthData.push({
                                value: parseNumber(row['miles']),
                                category: row['added_month']
                            })
                        }
                        chart_data.push({
                            name: vehicleName,
                            data: monthData
                        })
                    }
                    setTransportVehicleMonthChart(chart_data);
                }



               //Summary by vehicle
               entries = data['data']['summary']['vehicle_summary'] ?? [];
               let vehicleData = [];
               for(let vehicle in entries) {
                    let kgco2e = 0;
                   let entry = entries[vehicle];
                   let ghg_emissions = entry['ghg_emissions'];
                   for (let j = 0; j < ghg_emissions.length; j++) {
                       if (ghg_emissions[j]['measurement'] ==='kgCO2e') {
                           kgco2e = ghg_emissions[j]['amount'];
                       }
                   }
                   vehicleData.push({
                       "TotalMiles" : entry['miles'],
                       "Registration": vehicle,
                       "TotalkgCO2e" : parseNumber(kgco2e),
                       "TotaltCO2e"  : parseNumber(kgco2e/1000),
                   });
               }

               setTransportVehicleData(vehicleData);
            });
            //get data for the table

        }
        loadTransportWorkshopGraph();

    },[site, startDate, endDate]);


    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Transport Reporting</h5>
                    <hr/>
                    <ul>
                        <li>
                            <Link to={'/workshopLanding/' + site} className="nav-link">Transport Report</Link>
                        </li>
                        <li>
                            <Link to={'/transport-workshop/list/' + site} className="nav-link">Transport List</Link>
                        </li>
                    </ul>
                    <hr/>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={3}>
                            <div className="padRegion">
                                <Link to={'/workshops'} className="linkBack">&#11164; Back to Transport Workshop Sites</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={3}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                    <input
                                        type="submit"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        value="Search"
                                    />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashboard">
                        <div className="dashContainer">
                            <div className="dashContainerHeader"><strong>Transport Summary -{ siteName }</strong></div>
                            <Grid>
                                <TabStrip selected={selected} onSelect={handleSelect}>
                                    <TabStripTab title="Transport">
                                        <Cell small={12} medium={12} large={12}>
                                            <div className="dashContainerHeader">
                                                {TransportSiteTotal['miles']?
                                                    <table className="statsTable">
                                                        <tr>
                                                            <td>Total Miles: {TransportSiteTotal['miles']}</td>
                                                            <td>Total KgCO2e: {TransportSiteTotal['kgco2e']}</td>
                                                        </tr>
                                                    </table> : '' }
                                            </div>
                                            <EnergyChart
                                                chartData={TransportTotalChart}
                                                title="Transport Miles vs tCO2e by Month"
                                                xaxisTitle = " "
                                                yaxisTitle = "Day"
                                                type = "line"
                                            />
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                                <DataGrid
                                                    style={{
                                                        height: "600px",
                                                    }}
                                                    data={orderBy(TransportDataTable, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <Link to={'/transport-workshop/list/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                            See All Inputs
                                                        </Link>
                                                    </Toolbar>
                                                    <Column field="Registration" title="Registration" />
                                                    <Column field="Make" title="Make" />
                                                    <Column field="Model" title="Model" />
                                                    <Column field="Type" title="Type" />
                                                    <Column field="Miles" title="Miles" format="{0:n2}" />
                                                    <Column field="CO2eTotal" title="CO2eTotal" format="{0:n2}" />
                                                    <Column field="tCO2eTotal" title="tCO2eTotal" format="{0:n2}" />
                                                    <Column field="Date" title="Date" />
                                                    <Column field="EntryType" title="EntryType"></Column>
                                                </DataGrid>
                                        </Cell>
                                    </TabStripTab>
                                    <TabStripTab title="TransportByVehicle">
                                        <Grid>
                                            <Cell small={12} medium={12} large={12}>
                                                <Cell small={12} medium={12} large={12}>
                                                    <div className="chart-height">
                                                        <EnergyChart
                                                            chartData={TransportVehicleMonthChart}
                                                            title="Total Miles by Month per Vehicle"
                                                            xaxisTitle = " "
                                                            yaxisTitle = "Month"
                                                            type = "line"
                                                            height = "600"
                                                            legendPosition = "right"
                                                            inActiveFilter = "true"
                                                        />
                                                    </div>
                                                </Cell>
                                                <Cell small={12} medium={12} large={12}>
                                                    <div className="dashContainerHeader"><strong>Total Miles and KgCo2e per Vehicle</strong></div>
                                                    <DataGrid
                                                        style={{
                                                            height: "600px",
                                                        }}

                                                        data={filterBy(TransportVehicleData, filter)}
                                                        filterable={true}
                                                        filter={filter}
                                                        onFilterChange={(e) => setFilter(e.filter)}
                                                        sortable={true}
                                                        sort={sort}
                                                        onSortChange={sortChange}
                                                    >
                                                        <Column field="Registration" title="Registration" />
                                                        <Column field="TotalMiles" title="TotalMiles" format="{0:n2}" />
                                                        <Column field="TotalkgCO2e" title="kgCO2eTotal" format="{0:n2}" />
                                                        <Column field="TotaltCO2e" title="tCO2eTotal" format="{0:n5}" />
                                                    </DataGrid>
                                                </Cell>
                                            </Cell>
                                        </Grid>

                                    </TabStripTab>
                                </TabStrip>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default TransportWorkshopLanding;