import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React from "react";
// import * as React from "@types/react";

function InputLanding() {
    const { site } = useParams();

    const value = new Date();

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="energyNavigation">
                    <h5>Scope 1 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/2'} className="nav-link">Natural Gas</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/15'} className="nav-link">Burning Oil</Link>
                        </li>
                        <li>
                            <Link to={'/energy/'+site+'/16'} className="nav-link">Gas Oil</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Scope 2 Emission Sources</h5>
                    <ul>
                        <li>
                            <Link to={'/energy/'+site+'/1'} className="nav-link">Electric</Link>
                        </li>
                    </ul>
                    <hr/>
                    {/*<h5>Usage Type Sub-Sections</h5>*/}
                    {/*<ul className="submenuNav">*/}
                    {/*    <li>*/}
                    {/*        <Link to={'/meter/'+site+'/1'} className="nav-link">IT</Link>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        <Link to={'/meter/'+site+'/2'} className="nav-link">Kitchen</Link>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    {/*<hr/>*/}
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/energy-meters/'+site} className="nav-link">Energy Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/energy-meter-entries/'+site} className="nav-link">Energy Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/energy/'+site} className="nav-link">Energy Meter Entry Upload</Link>
                        </li>
                    </ul>
                    <hr/>
                    <h5>Digital Twin</h5>
                    <ul>
                        <li>
                            <Link to={'/twin/energy/'+site} className="nav-link">Digital Twin Overview</Link>
                        </li>
                    </ul>
                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/' + site}>&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Energy Upload Input</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={3} large={3}>
                                <div className="quickSelectTab">
                                    <h4>Energy Upload</h4>
                                    <hr/>
                                    <form>
                                        <select>
                                            <option value=''>Please Select a Fuel Type</option>
                                            <option value='1'>Electricity</option>
                                            <option value='2'>Natural Gas</option>
                                            <option value='5'>Diesel</option>
                                            <option value='9'>Fuel Oil</option>
                                            <option value='10'>Petrol</option>
                                            <option value='11'>Biofuel</option>
                                            <option value='15'>Burning Oil</option>
                                            <option value='16'>Gas Oil</option>
                                            <option value='6'>LPG</option>
                                        </select>
                                        <hr/>
                                        <input type='file' />
                                        <hr/>
                                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Upload</button>
                                    </form>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default InputLanding;