import * as React from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column, GridToolbar as Toolbar } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import "hammerjs";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {isNullOrEmptyString} from "@progress/kendo-data-query/dist/npm/utils";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import { formatDate } from '@progress/kendo-intl';
import { orderBy } from "@progress/kendo-data-query";
import $ from "jquery";
import {useCallback, useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import WaterNavbar from "./WaterNavbar";
import {FormatWaterEntries} from "./WaterHelper";
import WaterChart from "./WaterChart";

import {
    Chart,
    ChartBreadcrumb, ChartCategoryAxis, ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle, ChartValueAxis, ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import WaterDrillDownChart from "./WaterDrillDownChart";


function WaterLanding() {

    const navigate = useNavigate();
    const location = useLocation();

    const [waterMeterName, setWaterMeterName] = useState('');
    const [waterMeasure, setWaterAmount] = useState('');
    const [meterEntryList, setMeterEntryList] = React.useState([]);


    const [waterData, setWaterData] = useState([]);
    const [waterDataTable, setWaterDataTable] = useState([]);
    const [waterDataSplitChart, setWaterDataSplitChart] = useState([]);
    const [totalSummaryByCode, setTotalSummaryByCode] = useState([]);
    const [totalWaterSummary , setTotalWaterSummary] = useState([]);
    const [totalMqttSummary , setTotalMqttSummary] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const[isMqtt, setIsMqtt] = useState(false);

    const [waterMqttDataChart, setWaterMqttDataChart] = useState([]);
    const [waterMqttDataTable, setWaterMqttDataTable] = useState([]);

    const [siteName, setSiteName] = useState('');

    const params = useParams();
    const id = params['id'];
    const site = params['site'];


    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2016,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const initialSort = [
        {
            field: "Date",
            dir: "desc",
        },
    ];

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [sort, setSort] = React.useState(initialSort);

    const useSensorByDayData = (date) => {
        const [data, setData] = React.useState([]);
        const sensors_data_by_day = []
        React.useEffect(() => {
            const loadDayData = async () => {
                axiosApi.get(
                    'pillar/2/sensor-water-entries/meter-summary/',
                    {
                        params: {
                            'added_on': date,
                            'site_id' : site,
                            'pillar_two_water_type_id': id,
                        }
                    }
                ).then(function (response) {
                    if (response['data']['data']) {
                        let by_meter = response['data']['data']['summary']['by_water_meter'];

                        for (let i = 0; i < by_meter.length; i++) {

                            let list = by_meter[i]['list'];
                            let name = '';
                            let chart_data =[];
                            for (let i = 0; i < list.length; i++) {


                                let entry = list[i];
                                name= entry['meter_name'];
                                chart_data.push({
                                    value: entry['amount'],
                                    category: entry['on_time']? parseInt(entry['on_time'].substr(0,2)) : 0,
                                    duration: (entry['duration']!= '00:00:00') ? entry['duration'] :''
                                });
                                if(entry['off_time']) {
                                    chart_data.push({
                                        value: 0,
                                        category: parseInt(entry['off_time'].substr(0,2))
                                    });
                                }
                            }
                            sensors_data_by_day.push({
                                name: name,
                                data : chart_data
                            })
                        }
                        console.log(sensors_data_by_day);
                        setTimeout(() => setData(sensors_data_by_day), simulatedDelay);
                    }
                });
            }
            loadDayData();

        }, [date]);
        return data;
    };

    //Drilldown chart
    const simulatedDelay = 500;
    const DrilldownByDaySeries = (props) => (

    <ChartSeries>
        {useSensorByDayData(props.drilldownValue)?.map((item, idx) =>  (
                <ChartSeriesItem
                    key={idx}
                    type="line"
                    tooltip={{ visible: true }}
                    data={item.data}
                    name={item.name}
                    field="value" categoryField="category"
                    noteTextField="duration"
                    {...props}
                />
            ))}
        </ChartSeries>
    );

    const [drilldownState, setDrilldownState] = React.useState();
    const handleDrilldown = (e) => {
        setDrilldownState(e.nextState);
    };

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();
    },[site]);

    const loadMeterEntries = async () => {
        // Fetch meter entries
        const meterEntriesResponse = await axiosApi.get('/pillar/2/site-water-meter-entries', {
            params: {
                site_id: site,
                start_date: startDate,
                end_date: endDate
            }
        });
    
        // Extract meter entries data
        const meterEntriesData = meterEntriesResponse.data.data.list;
    
        if (meterEntriesData.length > 0) {
            // Extract unique meter IDs
            const uniqueMeterIds = [...new Set(meterEntriesData.map(entry => entry.meter_id))];
    
            // Fetch meter names for each unique meter ID
            const meterNamesPromises = uniqueMeterIds.map(async meterId => {
                const meterResponse = await axiosApi.get(`/pillar/2/site-water-meters/${meterId}`);
                return meterResponse.data.data.meter_name;
            });
    
            // Resolve all promises
            const meterNames = await Promise.all(meterNamesPromises);
    
            // Replace meter_ids with meter_names in meter entries data
            const meterEntriesWithNames = meterEntriesData.map(entry => ({
                ...entry,
                meter_name: meterNames[uniqueMeterIds.indexOf(entry.meter_id)]
            }));
    
            // Update state with meter entries including meter names
            setMeterEntryList(meterEntriesWithNames);
            // Calculate total amount
            const total = meterEntriesData.reduce((acc, entry) => acc + entry.amount, 0);
            setTotalAmount(total);
        }
    };
    loadMeterEntries();
    
    useEffect(() => {
        const loadWaterData = async () => {
          try {
            const response = await axiosApi.get("/pillar/2/site-water-meter-entries", {
              params: {
                site_id: site,
                start_date: startDate,
                end_date: endDate,
                by_meter: true,
              },
            });
    
            const data = response.data.data.list;
            setWaterData(data);
          } catch (error) {
            console.error("Error loading water data:", error);
          }
        };
    
        loadWaterData();
      }, [id, startDate, endDate, site]);
    
      // Function to format water data for DailyTimelineChart
      const formatWaterChartData = () => {
        if (!waterData || waterData.length === 0) {
          return []; // Return empty array if waterData is undefined or empty
        }
    
        return waterData.map((entry) => ({
          category: entry.date_added, // Assuming date_added is the timestamp
          name: entry.meter_name,
          value: entry.amount,
        }));
      };


    return (
        <Grid>
            <WaterNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={12} large={2}>
                            <div className="padRegion">
                                <Link to={'/sitelanding/' + site} className="linkBack">&#11164; Back to Site Landing</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={12} large={4}></Cell>

                        <Cell small={12} medium={12} large={6}>
                            <form className="k-form" onSubmit={handleDateSearch}>
                                <div className="date-filter-form">
                                    <div className="padAlignRight">
                                        <p>Start and End Dates:</p>
                                    </div>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={startDate}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="fromDate"
                                            id="startDate" max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <label className="k-form-field">
                                        <DatePicker
                                            width="100%"
                                            defaultValue={new Date()}
                                            required={true}
                                            defaultShow={false}
                                            format={"dd-MM-yyyy"}
                                            name="toDate"
                                            id="endDate"
                                            min={startDate} max={new Date()}
                                            onChange={handleChange}
                                        />
                                    </label>

                                <input
                                    type="submit"
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    value="Search"
                                />
                                </div>
                            </form>

                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Daily Water Usage by Meter -{ siteName }</strong></div>
                        <Grid className="display">
                            <TabStrip selected={selected} onSelect={handleSelect}>
                                <TabStripTab title="Daily Water Usage by Meter">
                                    <Cell small={12} medium={12} large={12}>
                                        <Cell small={12} medium={12} large={12}>
                                            <WaterChart
                                                chartData={formatWaterChartData()}
                                                title={"Daily Water Usage by Meter"}
                                                xaxisTitle = {waterMeasure}
                                                yaxisTitle = {"Water Actual"}
                                                type = "column"
                                                color = "green"
                                            />
                                            <div className="waterEstimate">
                                                <span></span>
                                                {"Water (Estimated)"}
                                            </div>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                            <Grid className="display">
                                                <Cell small={12} medium={3} large={3}>
                                                    <div className="quickSelectTab">
                                                        <h4>Usage</h4>
                                                        <p>Total: {totalAmount}</p>
                                                    </div>
                                                </Cell>
                                            </Grid>
                                        </Cell>
                                        <Cell small={12} medium={12} large={12}>
                                                <DataGrid
                                                    style={{
                                                        height: "400px",
                                                    }}
                                                    data={orderBy(meterEntryList, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e) => {
                                                        setSort(e.sort);
                                                    }}
                                                >
                                                    <Toolbar>
                                                        <Link to={'/water-meter-entries/' + site}  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary">
                                                            See All Inputs
                                                        </Link>
                                                    </Toolbar>
                                                    <Column field="id" title="id" width="150" />
                                                    <Column field="meter_name" title="Meter Name" width="150" />
                                                    <Column field="date_added" title="Date" />
                                                    <Column field="amount" title="Amount" format="{0:n2}"/>
                                                    <Column field="meter_reading" title="Meter Reading" format="{0:n2}"/>
                                                    <Column field="file_upload" title="File Upload"/>
                                                </DataGrid>
                                        </Cell>
                                    </Cell>
                                </TabStripTab>
                            </TabStrip>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WaterLanding;