import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React from "react";
// import * as React from "@types/react";

function WaterInputLanding() {
    const { site } = useParams();

    const value = new Date();

    return (
        <Grid>
            <Cell small={12} medium={2} large={2}>
                <div className="waterNavigation">
                    <h5>Inputs</h5>
                    <ul>
                        <li>
                            <Link to={'/water-meters/'+site} className="nav-link">Water Meters</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/water-meter-entries/'+site} className="nav-link">Water Meter Entries</Link>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={'/input/water/'+site} className="nav-link">Water Meter Entry Upload</Link>
                        </li>
                    </ul>
                    <hr/>

                </div>
            </Cell>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/water-meter-entries/' + site}>&#11164; Back to Water Meter Entries</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Water Upload Input</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={3} large={3}>
                                <div className="quickSelectTab">
                                    <h4>Water Upload</h4>
                                    <form>
                                        <hr/>
                                        <input type='file' />
                                        <hr/>
                                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Upload</button>
                                    </form>
                                </div>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default WaterInputLanding;