import React from 'react'
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import {Grid, Cell} from "react-foundation";
import { Grid as DataGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import "hammerjs";
import {Link, useNavigate, useParams} from "react-router-dom";
import { Window } from "@progress/kendo-react-dialogs";
import {orderBy} from "@progress/kendo-data-query";
import axiosApi from "../../api/axiosInstance";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import { filterBy } from "@progress/kendo-data-query";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem, ChartCategoryAxisTitle,
    ChartLegend, ChartSeries, ChartSeriesItem,
    ChartTitle,
    ChartTooltip
} from "@progress/kendo-react-charts";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {parseNumber} from "../form/format-helper";
import {formatNumber} from "@progress/kendo-intl";

const ViewCellLink = (props) => {
    return (
        <td>
            <a href={`/workshopLanding/${props.dataItem.id}`}>
                {props.dataItem.id}
            </a>
        </td>
    )
}
function TransportWorkshops() {
    const [siteList, setSiteList] = React.useState([]);

    const [transportMilesTile, setTransportMilesTile] = useState([]);
    const [transportEmissionsTile, setTransportEmissionsTile] = useState([]);
    const [transportVehicleChartlabels, setTransportVehicleChartlabels] = useState([]);
    const [transportVehicleChartData, setTransportVehicleChartData] = useState([]);


    const _export = React.useRef(null);
    const [filter, setFilter] = React.useState();

    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };
    const initialSort = [
        {
            field: "name",
            dir: "desc",
        },
    ];

    const [sort, setSort] = React.useState(initialSort);

    const sortChange = (event) => {
        setSiteList(getSites(event.sort));
        setSort(event.sort);
    };
    const getSites = (sort) => {
        return orderBy(siteList, sort);
    };

    const [searchFormState, setSearchFormState] = React.useState({
        fromDate: new Date(2019,8,1),
        toDate: new Date(),
    });
    const [startDate, setStartDate] = useState(searchFormState.fromDate);
    const [endDate, setEndDate] = useState(searchFormState.toDate);
    const handleChange = (event) => {
        setSearchFormState({
            ...searchFormState,
            [event.target.name]: event.target.value,
        });
    };
    const handleDateSearch = (event) => {
        event.preventDefault();
        setStartDate(searchFormState.fromDate);
        setEndDate(searchFormState.toDate);
    };


    useEffect(() => {
        const loadSites = async () => {
            axiosApi.get(
                '/pillar/2/transport-workshops',
                {
                    params: {}
                }).then(function (response) {

                // console.log(response.data);
                if (response['data']['data'].length > 0) {
                    setSiteList(response['data']['data']);
                }
            });
        }
        loadSites();
    },[]);

    useEffect(() => {
        let transportVehiclelabels = [];
        let transportVehicleData =[];

        const loadCurYearTransportGraph = async () => {

            let url = '/pillar/2/site-transports/company-summary/workshop';
            let params = {
                start_date: startDate,
                end_date: endDate,
                by_vehicle_type: 1
            };

            //get energy data for upload Data
            axiosApi.get(
                url,
                {
                    params:  params
                }
            ).then(function (response) {
                let entrycheck = response['data']['data']['summary'];

                let kgco2e = 0;
                if(entrycheck['overall'].length > 0) {
                    kgco2e = parseNumber(entrycheck['overall'][0]['total_amount']);
                }
                let totalmiles = entrycheck['total_miles'] ? parseNumber(entrycheck['total_miles']) : 0;


                setTransportMilesTile(totalmiles);
                setTransportEmissionsTile(kgco2e);

                // Get Transport by Vehicle Type

                let transport_by_vehicle = response['data']['data']['summary']['by_vehicle_type'];

                for (let i = 0; i < transport_by_vehicle.length; i++) {

                    let vehicle_name =  transport_by_vehicle[i]['vehicle_type_name'];
                    let kgco2e_amount = parseNumber(transport_by_vehicle[i]['ghg_emissions'][0]['total_amount']);

                    transportVehiclelabels.push(vehicle_name);
                    transportVehicleData.push(kgco2e_amount);
                }

            });
        }
        loadCurYearTransportGraph();

        setTransportVehicleChartlabels(transportVehiclelabels);
        setTransportVehicleChartData([{
            name : 'Transport Emissions (Kgco2e)',
            data : transportVehicleData
        }]);


    }, [startDate,endDate]);

    return (
        <div className="dashboard">
            <div className="selectionBar">
                <Grid>
                    <Cell small={12} medium={2} large={2}>
                        <div className="padRegion">
                            <Link to={''} className="linkBack">&#11164; Back to Home</Link>
                        </div>
                    </Cell>
                    <Cell small={12} medium={12} large={4}></Cell>

                    <Cell small={12} medium={12} large={6}>
                        <form className="k-form" onSubmit={handleDateSearch}>
                            <div className="date-filter-form">
                                <div className="padAlignRight">
                                    <p>Start and End Dates:</p>
                                </div>
                                <label className="k-form-field">
                                    <DatePicker
                                        width="100%"
                                        defaultValue={startDate}
                                        required={true}
                                        defaultShow={false}
                                        format={"dd-MM-yyyy"}
                                        name="fromDate"
                                        id="startDate" max={new Date()}
                                        onChange={handleChange}
                                    />
                                </label>
                                <label className="k-form-field">
                                    <DatePicker
                                        width="100%"
                                        defaultValue={new Date()}
                                        required={true}
                                        defaultShow={false}
                                        format={"dd-MM-yyyy"}
                                        name="toDate"
                                        id="endDate"
                                        min={startDate} max={new Date()}
                                        onChange={handleChange}
                                    />
                                </label>

                                <input
                                    type="submit"
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    value="Search"
                                />
                            </div>
                        </form>

                    </Cell>
                </Grid>
            </div>
            <div className="dashContainer">
                <div className="dashContainerHeader"><strong>All Transport Workshop Sites</strong></div>
                <Grid className="display"
                      style={{
                        height: "800px",
                        }}

                >

                    <Cell small={12} medium={12} large={6}>
                        <ExcelExport data={siteList} ref={_export}>
                            <DataGrid
                                style={{
                                    height: "800px",
                                }}

                                data={filterBy(siteList, filter)}
                                filterable={true}
                                filter={filter}
                                onFilterChange={(e) => setFilter(e.filter)}
                                sortable={true}
                                sort={sort}
                                onSortChange={sortChange}
                            >
                                <Toolbar>
                                    <button
                                        title="Export Excel"
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                        onClick={excelExport}
                                    >
                                        Export to Excel
                                    </button>
                                </Toolbar>
                                    <Column field="id" title="Id" width="150px" cell={ViewCellLink} />
                                    <Column field="name" title="Name"  />
                            </DataGrid>
                        </ExcelExport>
                    </Cell>
                    <Cell small={12} medium={12} large={6}>
                        <Cell small={12} medium={12} large={12}>
                            <p><span style={{color: "#ff6358", weight: "bold"}}>Total Miles: </span> {formatNumber(transportMilesTile,'n2')} Miles</p>
                            <p><span style={{color: "#ff6358", weight: "bold"}}>Transport Emissions: </span> {formatNumber(transportEmissionsTile,'n2')} kgco2e</p>
                        </Cell>
                        <Chart>
                            <ChartTitle text="Kgco2e by Vehicle Type" />
                            <ChartLegend position="bottom" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={transportVehicleChartlabels}>
                                    <ChartCategoryAxisTitle text="Vehicle Type" />
                                </ChartCategoryAxisItem>
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {transportVehicleChartData.map((item, idx) => (
                                    <ChartSeriesItem
                                        key={idx}
                                        type="bar"
                                        tooltip={{
                                            visible: true,
                                        }}
                                        data={item.data}
                                        name={item.name}
                                        stack={false}
                                    />
                                ))}
                            </ChartSeries>
                        </Chart>
                    </Cell>
                </Grid>
            </div>
        </div>
    );

}

export default TransportWorkshops;