import {Grid, Cell} from "react-foundation";
import {Link, useParams} from "react-router-dom";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import axios from "axios";
import $ from "jquery";
import React, {useEffect, useState} from "react";
import axiosApi from "../../api/axiosInstance";
import {GridToolbar as Toolbar} from "@progress/kendo-react-grid/dist/npm/GridToolbar";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid as DataGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import EnergyNavbar from "../EnergyNavbar";
// import * as React from "@types/react";

function EnergyMeters() {
    const [siteName, setSiteName] = useState('');
    const [metersList, setMetersList] = React.useState([]);

    const params = useParams();
    const site = params['site'];

    const value = new Date();

    useEffect(() => {
        const loadSite = async () => {
            axiosApi.get(
                '/sites/' + site,
            ).then(function (response) {
                if (response['data']['data']) {
                    setSiteName(response['data']['data']['name']);
                }
            });
        }
        loadSite();

        const loadMeters = async () => {
            axiosApi.get(
                '/pillar/2/site-energy-meters',
                {
                    params: {
                        site_id : site
                    }
                }).then(function (response) {

                 console.log(response.data);
                if (response['data']['data']['list'].length > 0) {
                    setMetersList(response['data']['data']['list']);
                }
            });
        }
        loadMeters();
    },[site]);
    return (
        <Grid>
            <EnergyNavbar/>
            <Cell small={12} medium={10} large={10}>
                <div className="selectionBar">
                    <Grid>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion">
                                <Link to={'/dashboard/energy/' + site}>&#11164; Back to Energy Dashboard</Link>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}></Cell>
                        <Cell small={12} medium={2} large={2}>
                            <div className="padRegion padAlignRight">
                                <p>Start and End Dates:</p>
                            </div>
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date(2016,8,1,0,0,0)} defaultShow={false} format={"dd-MM-yyyy"} name="startDate" id="startDate" />
                        </Cell>
                        <Cell small={12} medium={2} large={2}>
                            <DatePicker defaultValue={new Date()} defaultShow={false} format={"dd-MM-yyyy"} name="endDate" id="endDate" />
                        </Cell>
                    </Grid>
                </div>
                <div className="dashboard">
                    <div className="dashContainer">
                        <div className="dashContainerHeader"><strong>Energy Meters-{ siteName }</strong></div>
                        <Grid className="display">
                            <Cell small={12} medium={12} large={12}>
                                <DataGrid
                                    style={{
                                        height: "800px",
                                    }}
                                    data={metersList}
                                >
                                    <Column field="id" title="Id" width="150px" />
                                    <Column field="name" title="Name" />
                                    <Column field="energy_type" title="Energy Type" />

                                </DataGrid>
                            </Cell>
                        </Grid>
                    </div>
                </div>
            </Cell>
        </Grid>
    );
}

export default EnergyMeters;