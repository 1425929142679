import logo from './logo.svg';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import {useState, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
// import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {useNavigate} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Help from "./components/Help";
import Login from "./components/Login";
import Content from "./components/Content";
import NotFound from "./components/NotFound";
import Navbar from "./components/Navbar";
import EnergyDashboard from "./components/EnergyDashboard";
import CompanyLanding from "./components/CompanyLanding";
import SiteLanding from "./components/SiteLanding";
import Logout from "./components/Logout";
import ApplianceLanding from "./components/ApplianceLanding";
import TwinLanding from "./components/TwinLanding";
import InputLanding from "./components/InputLanding";
import AssetManagement from "./components/AssetManagement";
import Protected from "./components/Protected";
import Companies from "./components/manage/Companies";
import Conversion from "./components/manage/Conversion";
import Suppliers from "./components/manage/Suppliers";
import Users from "./components/manage/Users";
import WasteContractor from "./components/manage/WasteContractor";
import WasteContractorFacility from "./components/manage/WasteContractorFacility";
import Sites from "./components/sites/Sites";
import EnergyLanding from "./components/EnergyLanding";
import EnergyMeters from "./components/sites/EnergyMeters";
import EnergyMeterEntries from "./components/sites/EnergyMeterEntries";
import * as Biodiversity from "./components/biodiversity/index";
import TransportLanding from "./components/Transport/TransportLanding";
import TransportList from "./components/Transport/TransportList";
import WaterLanding from "./components/Water/WaterLanding";
import WaterMeters from "./components/sites/WaterMeters";
import WaterMeterEntries from "./components/sites/WaterMeterEntries";
import WaterInputLanding from "./components/Water/WaterInputLanding";
import AddWaterMeter from "./components/Water/AddWaterMeter";
import AddWaterEntry from "./components/Water/AddWaterEntry";
import TransportWorkshopLanding from "./components/Transport/TransportWorkshopLanding";
import TransportWorkshopList from "./components/Transport/TransportWorkshopList";
import TransportWorkshops from "./components/Transport/TransportWorkshops";
import WasteLanding from "./components/Waste/WasteLanding";
import WasteEntries from "./components/sites/WasteEntries";
import PredectiveModel from "./components/Predective/PredectiveModel";
import CompanyTargets from "./components/CompanyTargets";
import AddCompanyTargets from "./components/AddCompanyTargets";



function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <Router>
            <div>
                <Navbar/>
                <Routes>
                    <Route path='*' element={<NotFound/>}/>
                    <Route path='/notfound' element={<NotFound/>}/>
                    <Route exact="true" path='/' element={<Protected><Content/></Protected>}/>
                    <Route path='/landing/:site' element={<Protected><CompanyLanding/></Protected>}/>
                    <Route path='/sitelanding/:site' element={<Protected><SiteLanding/></Protected>}/>
                    <Route path='/dashboard/energy/:site' element={<Protected><EnergyDashboard/></Protected>}/>
                    <Route path='/input/energy/:site' element={<Protected><InputLanding/></Protected>}/>
                    <Route path='/twin/energy/:site' element={<Protected><TwinLanding/></Protected>}/>
                    <Route path='/asset/import' element={<Protected><AssetManagement/></Protected>}/>
                    <Route path='/help' element={<Protected><Help/></Protected>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/logout' element={<Logout/>}/>
                    <Route path='/energy/:site/:id' element={<Protected><EnergyLanding/></Protected>}/>

                    <Route path='/meter/:site/:id' element={<Protected><ApplianceLanding/></Protected>}/>
                    {/* Manage Module Routes */}
                    <Route path='/manage/companies' element={<Protected><Companies/></Protected>}/>
                    <Route path='/manage/conversion' element={<Protected><Conversion/></Protected>}/>
                    <Route path='/manage/wastecontractor' element={<Protected><WasteContractor/></Protected>}/>
                    <Route path='/manage/wastecontractorfacility'
                           element={<Protected><WasteContractorFacility/></Protected>}/>
                    <Route path='/manage/suppliers' element={<Protected><Suppliers/></Protected>}/>
                    <Route path='/manage/users' element={<Protected><Users/></Protected>}/>
                    <Route path='/sites' element={<Protected><Sites/></Protected>}/>
                    <Route path='/energy-meters/:site' element={<Protected><EnergyMeters/></Protected>}/>
                    <Route path='/energy-meter-entries/:site/:id' element={<Protected><EnergyMeterEntries/></Protected>}/>
                    {/* Biodiversity Routes */}
                    <Route path='/biodiversity/dashboard/:site' element={<Biodiversity.Dashboard/>}/>
                    <Route path='/biodiversity/status/:site' element={<Protected><Biodiversity.BiodiversityStatus/></Protected>}/>
                    <Route path='/biodiversity/addinitative/:site' element={<Biodiversity.AddInitiative/>}/>
                    <Route path='/biodiversity/initatives/:site' element={<Protected><Biodiversity.Initiatives/></Protected>}/>
                    <Route path='/biodiversity/report/:site' element={<Protected><Biodiversity.Landing/></Protected>}/>
                    {/* Transport Routes */}
                    <Route path='/dashboard/TransportLanding/:site' element={<Protected><TransportLanding/></Protected>}/>
                    <Route path='/transport/list/:site' element={<Protected><TransportList/></Protected>}/>
                    {/* Water Routes */}
                    <Route path='/dashboard/WaterLanding/:site' element={<Protected><WaterLanding/></Protected>}/>
                    <Route path='/water-meters/:site' element={<Protected><WaterMeters/></Protected>}/>
                    <Route path='/water-meter-entries/:site' element={<Protected><WaterMeterEntries/></Protected>}/>
                    <Route path='/input/water/:site' element={<Protected><WaterInputLanding/></Protected>}/>
                    <Route path='/input/add/water-meter/:site' element={<Protected><AddWaterMeter/></Protected>}/>
                    <Route path='/input/add/water-entry/:site' element={<Protected><AddWaterEntry/></Protected>}/>

                    {/*Transport Workshop Routes */}
                    <Route path='/workshopLanding/:site' element={<Protected><TransportWorkshopLanding/></Protected>}/>
                    <Route path='/transport-workshop/list/:site' element={<Protected><TransportWorkshopList/></Protected>}/>
                    <Route path='/workshops' element={<Protected><TransportWorkshops/></Protected>}/>

                    {/* Waste Routes */}
                    <Route path='/dashboard/WasteLanding/:site' element={<Protected><WasteLanding/></Protected>}/>
                    <Route path='/waste-entries/:site' element={<Protected><WasteEntries/></Protected>}/>

                    {/*Predective Model Route*/}
                    <Route path='/predictive-model' element={<Protected><PredectiveModel/></Protected>}/>

                    {/*Company Target Route*/}
                    <Route path='/company-targets' element={<Protected><CompanyTargets/></Protected>}/>
                    <Route path='/add-company-targets' element={<Protected><AddCompanyTargets/></Protected>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
